export default async function userRegister(registrationForm) {
    const parameters = {
        headers: {
            "content-type": "application/json; charset=UTF-8"
        },
        body: JSON.stringify(registrationForm),
        method: "POST"
    };
    const response = await fetch("/register/user", parameters);
    
    return await response.json();
}

