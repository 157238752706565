import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./style.scss";
import Icon, { ICONS } from "../../components/Icon";

const StepBullet = ({ label, index, onClick, active, completed, testId }) => {
    const bulletClasses = classNames(
        "step-bullet",
        active ? "active" : "",
        completed ? "completed" : ""
    );

    return (
        <div onClick={() =>  completed && onClick(index) } className={bulletClasses}>
            <div className="bullet" data-testid={testId}>
                {completed && <Icon size="xs" name={ICONS.CHECK} />}
            </div>
            <span>{label}</span>
        </div>
    );
};

StepBullet.propTypes = {
    label: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    active: PropTypes.bool,
    completed: PropTypes.bool,
    testId: PropTypes.string
};

export default StepBullet;