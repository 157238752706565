import MeasureItem from "components/MeasureItem";
import Modal from "components/Modal";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import Button from "shared/components/buttons/Button";
import OkWarning from "shared/components/OkWarning";
import { ICONS } from "shared/components/Icon";
import MeasureForm from "./MeasureForm";
import bookMeasureEdit from "redux/actions/books/bookMeasureEdit";
import bookMeasureRemove from "redux/actions/books/bookMeasureRemove";
import bookMeasureAdd from "redux/actions/books/bookMeasureAdd";
import { snackbarError } from "shared/redux/actions/Snackbar";
import OkDialog from "shared/components/OkDialog";

import "./style.scss";

const Measures = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { osbn } = useParams();
    const { books, published : publishedBooks } = useSelector(state => state.books);

    const [showMeasureModal, setShowMeasureModal] = useState(false);
    const [deleteMeasure, setDeleteMeasure] = useState(null);
    const [selectedMeasure, setSelectedMeasure] = useState();
    const [pendingMeasure, setPendingMeasure] = useState();
    const [changeNotes, setChangeNotes] = useState(pendingMeasure?.changes?.changes || "");
    const [showChangeNotesDialog, setShowChangeNotesDialog] = useState(false);

    const book = books[osbn];
    const publishedBook = publishedBooks[osbn];

    const { measures } = book;

    const keys = Object.keys(measures);

    const onEdit = (measureId) => {
        setShowMeasureModal(true);
        setSelectedMeasure(measureId);
    };

    const onDelete = (measureId) => {
        const searchString = JSON.stringify(book.chapters);
        if (searchString.includes(measureId)) {
            dispatch(snackbarError(t("book.measures.delete.inUse")));
        } else {
            setDeleteMeasure(measureId);
        }
    };


    const onChange = async (measure) => {

        if (measure.id) {
            if (
                publishedBook &&
                publishedBook.measures[measure.id] &&
                !showChangeNotesDialog
            ) {
                // measure exists and has been published
                // we need change notes
                await setPendingMeasure(measure);
                setShowChangeNotesDialog(true);
            }
            else {
                // measure exists but has not yet been published
                await dispatch(bookMeasureEdit(osbn, measure, changeNotes));
                setShowChangeNotesDialog(false);
                setShowMeasureModal(false);
            }
        } else {
            // measure is completely new, just add it
            await dispatch(bookMeasureAdd(osbn, measure));
            setShowMeasureModal(false);
        }
    };

    const renderConfirmDelete = () => {
        return (
            <OkWarning
                title={t("book.measures.delete.confirm.title")}
                message={t("book.measures.delete.confirm.message")}
                onCancel={() => setDeleteMeasure(false)}
                onConfirm={() => {
                    dispatch(bookMeasureRemove(osbn, deleteMeasure));
                    setDeleteMeasure(false);
                }}
            />
        );
    };

    const chaptersString = JSON.stringify(book.chapters);

    return (
        <div className="section-measures">
            <div className="section-title">
                <h2>{t("book.measures.title")}</h2>
                <Button
                    label={t("book.measures.add")}
                    iconRight={ICONS.PLUS}
                    onClick={() => {
                        setSelectedMeasure(null);
                        setShowMeasureModal(true);
                    }}
                />
            </div>
            <div className="divider" />
            {keys.length > 0 ? keys.map((key) => {
                const measure = measures[key];
                const published = !!publishedBook && publishedBook.measures[measure.id];
                return (
                    <MeasureItem
                        published={published}
                        measure={measure}
                        onEdit={onEdit}
                        onChange={onChange}
                        onDelete={() => onDelete(key)}
                        chaptersString={chaptersString}
                    />
                );
            }) :
                <span>{t("book.edit.measures.empty")}</span>
            }
            <div className="spacer" />
            <div className="divider" />
            {showMeasureModal &&
                <Modal
                    title={t("book.measures.modal.title")}
                    onClose={() => setShowMeasureModal(false)}
                    tabs={[{
                        tabName: "info",
                        component:
                            <MeasureForm
                                measure={selectedMeasure ? measures[selectedMeasure] : null}
                                onChange={onChange}
                                onCancel={() => setShowMeasureModal(false)}
                                osbn={osbn}
                            />
                    }]}
                />
            }
            {showChangeNotesDialog &&
                <OkDialog
                    title={t("measure.changeLog.title")}
                    message={t("measure.changeLog.message")}
                    label={t("measure.changeLog.fieldLabel")}
                    onCancel={() => setShowChangeNotesDialog(false)}
                    onChange={(notes) => setChangeNotes(notes)}
                    onConfirm={() => onChange(pendingMeasure)}
                    defaultValue={pendingMeasure?.changes?.changes}
                />
            }
            {deleteMeasure && renderConfirmDelete()}
        </div>
    );
};

export default Measures;