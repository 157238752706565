import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import Dossier from "./types/Dossier";
import OpenQuestion from "./types/OpenQuestion";
import Numeric from "./types/Numeric";
import ActivateChapters from "./types/ActivateChapters";
import MultipleChoice from "./types/MultipleChoice";
import Info from "./types/Info";

import InputSelect from "shared/components/inputs/InputSelect";
import { FLOW_ITEM_TYPES, TRUE_FALSE } from "../../constants";
import Icon, { ICONS } from "shared/components/Icon";

import bookFlowItemUpdate from "redux/actions/books/bookFlowItemUpdate";
import bookFlowItemAdd from "redux/actions/books/bookFlowItemAdd";

import Date from "./types/Date";

import "./style.scss";

const EMPTY = "empty";

const FLOW_ITEM_ICONS = {
    [FLOW_ITEM_TYPES.INFO]: ICONS.INFO_CIRCLE,
    [FLOW_ITEM_TYPES.OPEN]: ICONS.QUESTION,
    [FLOW_ITEM_TYPES.DOSSIER]: ICONS.DOSSIER,
    [FLOW_ITEM_TYPES.ACTIVATE_CHAPTERS]: ICONS.BOOK,
    [FLOW_ITEM_TYPES.MULTIPLE_CHOICE]: ICONS.LIST,
    [FLOW_ITEM_TYPES.TRUE_FALSE]: ICONS.LIST,
    [FLOW_ITEM_TYPES.DATE]: ICONS.CALENDAR,
    [FLOW_ITEM_TYPES.NUMERIC]: ICONS.COUNT,
    [EMPTY]: ICONS.SITEMAP
};

const getType = (flowItem) => {
    if (!flowItem?.type) {
        return EMPTY;
    } else if (flowItem.type === TRUE_FALSE) {
        // true false will be removed in the future
        return FLOW_ITEM_TYPES.MULTIPLE_CHOICE;
    } else {
        return flowItem.type;
    }
};

const FlowItemForm = ({
    flowItem,
    id,
    osbn,
    chapterId,
    onCancel
}) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { books } = useSelector(state => state.books);
    const [type, setType] = useState(getType(flowItem));

    flowItem = flowItem ? flowItem : {};

    const { items } = books[osbn].chapters[chapterId];
    const itemKeys = Object.keys(items);

    const flowItems = itemKeys.map((key) => {
        const item = items[key];
        return {
            label: item.label,
            value: key
        };
    });

    flowItems.push({
        label: t("book.flowItem.chapterEnd"),
        value: "END"
    });

    const renderType = () => {

        const types = {
            [FLOW_ITEM_TYPES.INFO]: Info,
            [FLOW_ITEM_TYPES.OPEN]: OpenQuestion,
            [FLOW_ITEM_TYPES.DOSSIER]: Dossier,
            [FLOW_ITEM_TYPES.ACTIVATE_CHAPTERS]: ActivateChapters,
            [FLOW_ITEM_TYPES.MULTIPLE_CHOICE]: MultipleChoice,
            [FLOW_ITEM_TYPES.TRUE_FALSE]: MultipleChoice,
            [FLOW_ITEM_TYPES.NUMERIC]: Numeric,
            [FLOW_ITEM_TYPES.DATE]: Date,
            [EMPTY]: function empty() { return <span>{t("book.flowItem.empty.label")}</span>; }
        };

        const Type = types[type];

        return (
            <Type
                flowItem={flowItem}
                flowItems={flowItems}
                onCancel={onCancel}
                onChange={(item) => { onChange(item); }}
            />
        );
    };

    const types = Object.values(FLOW_ITEM_TYPES).map((value) => {

        const indicatorClass = classNames(
            "list-item-indicator",
            value
        );

        return {
            label: t(`book.flowItem.${value}.label`),
            value,
            indicator: function Indicator() {
                return (
                    <span className={indicatorClass}>
                        <Icon name={FLOW_ITEM_ICONS[value]} />
                    </span>
                );
            }
        };
    });


    const onChange = async (changes) => {
        if (id) {
            await dispatch(bookFlowItemUpdate(osbn, chapterId, id, { ...changes, type }));
        } else {
            await dispatch(bookFlowItemAdd(osbn, chapterId, { ...changes, type }));
        }
        onCancel();
    };

    return (
        <div className="flow-item-form">
            <h5 className="subtitle">{t("book.flowItem.select.title")}</h5>
            <InputSelect
                label={t("book.flowItem.type")}
                defaultValue={type}
                onChange={({ target }) => setType(target.value)}
                items={types}
            />
            <div className="divider" />
            <h5 className="subtitle">{t(`book.flowItem.${type}.title`)}</h5>
            {renderType()}
        </div>
    );
};

FlowItemForm.propTypes = {
    flowItem: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    osbn: PropTypes.string.isRequired,
    chapterId: PropTypes.string.isRequired
};


export default FlowItemForm;