import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "shared/components/buttons/Button";
import { ICONS } from "shared/components/Icon";
import ChapterItem from "components/ChapterItem";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Modal from "components/Modal";
import ChapterForm from "./ChapterForm";
import OkWarning from "shared/components/OkWarning";
import bookChapterRemove from "redux/actions/books/bookChapterRemove";
import { snackbarError } from "shared/redux/actions/Snackbar";

import "./style.scss";

const Chapters = () => {

    const { t } = useTranslation();
    const { osbn } = useParams();
    const dispatch = useDispatch();

    const { books } = useSelector(state => state.books);
    const [showChapterModal, setShowChapterModal] = useState(false);
    const [selectedChapter, setSelectedChapter] = useState(null);
    const [removeChapter, setRemoveChapter] = useState(false);

    const book = books[osbn];
    const { chapters } = book;
    const keys = Object.keys(chapters);


    const onEdit = (chapterId) => {
        setShowChapterModal(true);
        setSelectedChapter(chapterId);
    };

    const onRemove = (chapterId) => {
        const searchString = JSON.stringify(book);
        const exp = new RegExp(chapterId, "g");
        const isConnected = (searchString.match(exp) || []).length >= 3;

        if (book.defaultChapters?.includes(chapterId)) {
            dispatch(snackbarError(t("book.chapters.delete.inUse.defaultChapter")));
        } else if (book.startItem === chapterId) {
            dispatch(snackbarError(t("book.chapters.delete.inUse.startItem")));
        } else if (isConnected) {
            dispatch(snackbarError(t("book.chapters.delete.inUse.chapters")));
        } else {
            setRemoveChapter(chapterId);
        }
    };

    const renderConfirmRemove = () => {
        return (
            <OkWarning
                title={t("book.chapter.remove.confirm.title")}
                message={t("book.chapter.remove.confirm.message")}
                onConfirm={() => {
                    dispatch(bookChapterRemove(osbn, removeChapter));
                    setRemoveChapter(false);
                }}
                onCancel={() => setRemoveChapter(false)}
            />
        );
    };

    return (
        <div className="section-chapters">
            <div className="section-title">
                <h2>{t("book.edit.chapters.title")}</h2>
                <Button
                    label={t("book.edit.chapters.add")}
                    iconRight={ICONS.PLUS}
                    onClick={() => {
                        setSelectedChapter(null);
                        setShowChapterModal(true);
                    }}
                />
            </div>
            <div className="divider" />
            {keys.length > 0 ?
                keys.map((key, index) => {
                    return (
                        <ChapterItem
                            key={key}
                            index={index + 1}
                            osbn={osbn}
                            chapterId={key}
                            chapter={chapters[key]}
                            onEdit={onEdit}
                            onRemove={onRemove}
                        />);
                }) : <span>{t("book.edit.chapters.empty")}</span>}
            {showChapterModal &&
                <Modal
                    title={t("chapter.modal.title")}
                    onClose={() => setShowChapterModal(false)}
                    tabs={[{
                        tabName: "info",
                        component:
                            <ChapterForm
                                chapter={selectedChapter ? chapters[selectedChapter] : null}
                                onClose={() => setShowChapterModal(false)}
                            />
                    }]}
                />
            }
            {removeChapter && renderConfirmRemove()}
        </div>
    );
};

export default Chapters;