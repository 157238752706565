import client from "../../../graphql/client";
import { loader } from "graphql.macro";
import { USER_LOADED } from "../../types";

const fetchUserProfile = loader("shared/graphql/queries/users/fetchUserProfile.graphql");

export function userLoad() {
    return async (dispatch) => {
        try {
            const response = await client.query({ query: fetchUserProfile });
            const { userProfile } = response.data;
            dispatch({ type: USER_LOADED, userProfile });
        } catch (error) {
            console.log(error);
        }
    };
}
