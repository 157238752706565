import client from "graphql/client";
import i18next from "i18next";
import { loader } from "graphql.macro";
import { BOOKS_DRAFTS_LOADED } from "redux/types";
import { snackbarError } from "shared/redux/actions/Snackbar";

const fetchDraftBooks = loader("graphql/queries/books/fetchDraftBooks.graphql");

export function booksLoadDrafts() {
    return async (dispatch, getState) => {
        const { current: organisation } = getState().organisation;
        
        try {
            await client.query({
                query: fetchDraftBooks,
                variables: { organisationId: organisation.id },
                fetchPolicy: "network-only"
            }).then(({ data }) => {
                const { draftBooks } = data;
                const booksMapped = {};
                            
                draftBooks.forEach((book) => {
                    const measures  = JSON.parse(book.measures);
                    const chapters = JSON.parse(book.chapters);
                    const measureKeys = Object.keys(measures);
                    const chapterKeys = Object.keys(chapters);

                    measureKeys.forEach((key)=> {
                        measures[key].id = key;
                    });
                    chapterKeys.forEach((key)=> {
                        chapters[key].id = key;
                    });

                    booksMapped[book.osbn] = {
                        ...book,
                        measures,
                        chapters
                    };
                });

                dispatch({
                    type: BOOKS_DRAFTS_LOADED,
                    books: booksMapped
                });
            });

        } catch (error) {
            console.error(error);
            dispatch(snackbarError(i18next.t("books.loading.failed")));
        }
    };
}