import produce from "immer";

import {
    BOOK_CHAPTERS_SORTED, 
    BOOK_CHAPTER_REMOVED, 
    BOOK_CHAPTER_SORTED, 
    BOOK_CHAPTER_UPDATED, 
    BOOK_FLOW_ITEM_REMOVED, 
    BOOK_FLOW_ITEM_UPDATED, 
} from "../types";

const initialState = {
    book: {
        label: "",
        version: 0,
        chapters: {},
        startItem: null,
        osbn: null,
    },
    loading: false
};

const bookReducer = produce((draft, action) => {
    switch (action.type) {
        case BOOK_FLOW_ITEM_UPDATED: {
            const { chapterId, flowItemId, flowItem } = action;
            const hasItems = Object.keys(draft.chapters[chapterId].items).length > 0;
            
            draft.chapters[chapterId].version = draft.version;
            draft.chapters[chapterId].items[flowItemId] = flowItem;

            if (!hasItems) {
                draft.chapters[chapterId].startItem = flowItemId;

            } else if (
                !draft.chapters[chapterId].items[draft.chapters[chapterId].startItem]) {
                const firstItem = Object.keys(draft.chapters[chapterId].items)[0];
                draft.chapters[chapterId].startItem = firstItem;
            }

            break;
        }
        case BOOK_FLOW_ITEM_REMOVED: {
            const { chapterId, flowItemId } = action;
            draft.chapters[chapterId].version = draft.version;

            delete draft.chapters[chapterId].items[flowItemId];

            if (draft.chapters[chapterId].startItem === flowItemId) {
                const newFirstItem = Object.keys(draft.chapters[chapterId].items)[0];
                draft.chapters[chapterId].startItem = newFirstItem;
            }
            break;
        }
        case BOOK_CHAPTER_SORTED: {
            const { chapterId, sortedItems } = action;
            draft.chapters[chapterId].items = sortedItems;
            break;
        }
        case BOOK_CHAPTERS_SORTED: {
            const { chapters } = action;
            draft.chapters = chapters;
            break;   
        }
        case BOOK_CHAPTER_REMOVED: {
            const { chapterId } = action;
            delete draft.chapters[chapterId];
            break;   
        }
        case BOOK_CHAPTER_UPDATED: {
            const { chapterId, chapter } = action;

            if (draft.chapters[chapterId]) {
                draft.chapters[chapterId].label = chapter.label;
                draft.chapters[chapterId].hero = chapter.hero;
                draft.chapters[chapterId].startItem = chapter.startItem;
                draft.chapters[chapterId].version = draft.version;

            } else {
                draft.chapters[chapterId] = {
                    label: chapter.label,
                    hero: chapter.hero,
                    version: draft.version,
                    items: {}
                };
            }
            break;
        }
        default:
            return;
    }
}, initialState);

export default bookReducer;
