import client from "graphql/client";
import i18next from "i18next";
import { loader } from "graphql.macro";
import { BOOK_LOADED } from "redux/types";
import { snackbarSuccess, snackbarError } from "shared/redux/actions/Snackbar";
import createBookFromData from "helpers/createBookFromData";

const editBookMeasure = loader("graphql/mutations/books/editBookMeasure.graphql");

export default function bookMeasureEdit(osbn, measure, changeNotes) {
    return async (dispatch, getState) => {
        const { current: organisation } = getState().organisation;

        try {
            const { data } = await client.mutate({
                mutation: editBookMeasure,
                variables: {
                    organisationId: organisation.id,
                    osbn,
                    changeNotes,
                    measure: {
                        id: measure.id,
                        label: measure.label,
                        every: measure.every,
                        phaseShift: measure.phaseShift,
                        tasks: measure.tasks,
                        archived: measure.archived
                    }
                },
            });

            const { editBookMeasure: bookData } = data;

            const book = createBookFromData(bookData);

            dispatch({
                type: BOOK_LOADED,
                id: osbn,
                book,
            });
            
            dispatch(snackbarSuccess(i18next.t("books.edit.success")));

        } catch (error) {
            console.error(error);
            dispatch(snackbarError(i18next.t("books.edit.failed")));
        }
    };
}