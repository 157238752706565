export const ROLE_LEVELS = {
    MANAGER: "MANAGER",
};

export const MENU_ITEMS = [
    {
        label: "books.menu.books",
        path: "/books",
        iconName: "book",
        roleLevel: ROLE_LEVELS.MANAGER
    },
    {
        label: "books.menu.bookInvites",
        path: "/invites",
        iconName: "users",
        roleLevel: ROLE_LEVELS.MANAGER
    },
];


export const STATUS_REVOKED = "REVOKED";
export const STATUS_ACCEPTED = "ACCEPTED";
export const STATUS_SENT = "SENT";
export const STATUS_RESENT = "RESENT";

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;


export const FLOW_ITEM_TYPES = {
    INFO: "textInfo",
    OPEN: "openQuestion",
    MULTIPLE_CHOICE: "selectOption",
    ACTIVATE_CHAPTERS: "activateBookChapters",
    DOSSIER: "dossierWithTasks",
    NUMERIC: "numericValue",
    DATE: "dateInput"
};

export const TRUE_FALSE =  "trueOrFalse";

