import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import MeasureTaskItem from "components/MeasureTaskItem";
import ButtonAdd from "shared/components/buttons/ButtonAdd";
import InputText from "shared/components/inputs/InputText";
import PeriodSelector from "shared/components/PeriodSelector";
import Button from "shared/components/buttons/Button";
import ButtonAnchor from "shared/components/buttons/ButtonAnchor";

const MeasureForm = ({
    measure,
    onChange,
    onCancel,
}) => {

    const { t } = useTranslation();
    const { control, handleSubmit, errors } = useForm();
    const [tasks, setTasks] = useState(measure?.tasks || []);

    const isArchived = !!measure?.archived;

    const [period, setPeriod] = useState({
        every: measure?.every,
        phaseShift: measure?.phaseShift
    });

    const onPeriodChanged = (period) => {
        setPeriod(period);
    };

    const onTaskChanged = (index, task) => {
        const tasksCopy = [...tasks];
        tasksCopy[index] = task;
        setTasks(tasksCopy);
    };

    const onTaskDelete = (index) => {
        const tasksCopy = [...tasks];
        tasksCopy.splice(index, 1);
        setTasks(tasksCopy);
    };

    const onTaskAdded = () => {
        const tasksCopy = [...tasks];
        tasksCopy.push({
            label: "",
            description: "",
            id: new Date().getTime()
        });
        setTasks(tasksCopy);
    };

    const renderTasks = () => {
        if (tasks.length === 0) {
            return (
                <p className="error-required">
                    {t("measure.form.task.required")}
                </p>
            );
        } else {
            return tasks.map((task, index) => {
                return (
                    <MeasureTaskItem
                        task={task}
                        index={index}
                        onChange={onTaskChanged}
                        onDelete={onTaskDelete}
                        key={task.id || index}
                    />
                );
            });
        }
    };

    const updateMeasure = (form) => {
        onChange({
            id: measure?.id || undefined,
            label: form.label,
            tasks,
            every: period.every,
            phaseShift: period.phaseShift
        });
    };

    return (
        <div className="modal-form">
            <h3>{t("measure.form.general")}</h3>
            <div className="spacer" />
            <InputText
                name="label"
                label={t("measure.form.label")}
                defaultValue={measure?.label}
                control={control}
                rules={{ required: true }}
                error={errors.label}
                dataTestId="label"
            />
            <PeriodSelector
                onChange={onPeriodChanged}
                defaultPeriod={period}
            />
            <div className="spacer" />
            <h3>{t("measure.form.tasks")}</h3>
            <div className="spacer" />
            {renderTasks()}
            <ButtonAdd
                onClick={onTaskAdded}
                label={t("measure.form.task.add")}
            />
            <div className="divider" />
            <div className="cols">
                <div className="col">
                    <ButtonAnchor
                        label={t("button.changeLog.label")}
                        onClick={handleSubmit(updateMeasure)}
                    />
                </div>
                <div className="container-buttons col">
                    <Button
                        outlined
                        spacedRight
                        label={t("button.cancel.label")}
                        onClick={onCancel}
                    />
                    <Button
                        disabled={tasks.length === 0 || isArchived}
                        label={t("button.save.label")}
                        onClick={handleSubmit(updateMeasure)}
                    />
                </div>
            </div>
        </div>
    );
};

MeasureForm.propTypes = {
    measure: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    osbn: PropTypes.string.isRequired,
};

export default MeasureForm;