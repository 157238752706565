import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useEffect } from "react";

import Icon, { ICONS } from "../Icon";

import "./style.scss";

let hideTimeout = null;

const DotMenu = ({
    menuItems,
    icon,
    dark
}) => {

    const [show, setShown] = useState(null);
    const [dontRender, setDontRender] = useState(true);

    useEffect(() => {
        hideTimeout && clearTimeout(hideTimeout);
        if (show) {
            setDontRender(false);
        } else {
            hideTimeout = setTimeout(() => {
                setDontRender(true);
            }, 500);
        }
    }, [show]);


    const menuClasses = classNames(
        "dot-menu",
        show !== null ? show ? "show" : "hide" : "",
        dontRender && "dont-render"
    );


    const dotMenuClasses = classNames("dot-menu-button", dark && "dark");

    return (
        <>
            <a
                className={dotMenuClasses}
                tabIndex="-1"
                onBlur={() => setShown(false)}
                onClick={() => setShown(true)}
            >
                <Icon
                    inline
                    name={icon || ICONS.MENU}
                />
            </a>
            <div className={menuClasses}>
                {menuItems.map((item, index) =>
                    <span
                        index={index}
                        key={index}
                        onMouseDown={item.action}
                    >
                        {item.label}
                    </span>
                )}
            </div>
        </>
    );

};

DotMenu.propTypes = {
    menuItems: PropTypes.array.isRequired,
    icon: PropTypes.string,
    dark: PropTypes.bool
};

export default DotMenu;