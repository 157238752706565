import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import Icon from "../../shared/components/Icon";
import { MENU_ITEMS } from "../../constants";
import organisationSet from "redux/actions/organisation/organisationSet";
import OrganisationSelector, { MODULE_FILTERS } from "shared/components/OrganisationSelector";
import Modules from "shared/components/Modules";

import "./style.scss";

const Navigation = ({ userRoleLevel }) => {

    const [t] = useTranslation();
    const dispatch = useDispatch();
    const { organisations, current } = useSelector(state => state.organisation);

    const menuItems = () => {
        return MENU_ITEMS.map((menuItem, index) => {
            const { label, path, iconName, className, roleLevel } = menuItem;
            if (userRoleLevel > roleLevel) {
                return null;
            }
            const classes = classnames("menu-item", className ? className : "");
            return (
                <NavLink
                    key={`navigation-${index}`}
                    className={classes}
                    to={path}
                    activeClassName="is-active">
                    <Icon name={iconName} spacedRight />
                    <span className="has-text-white">
                        {t(label)}
                    </span>
                </NavLink>
            );
        });
    };

    return (
        <nav role="navigation" className="main-navigation">
            <div className="navigation-left">
                <Link className="navigation-logo" to="/">
                    <img
                        alt="OKcomply Books"
                        src={require("../../assets/img/logo.png")}
                    />
                    <span>{t("books.menu.library")}</span>
                </Link>
                <div className="menu-items">
                    {menuItems()}
                </div>
            </div>
            <div className="navigation-right">
                <div className="navigation-big organisation">
                    {organisations?.length > 0 &&
                        <OrganisationSelector
                            organisations={organisations}
                            onOrganisationChanged={async (index) => {
                                await dispatch(organisationSet(index));
                                window.location.reload();
                            }}
                            filterModule={MODULE_FILTERS.PUBLISH}
                        />
                    }
                </div>
                <Modules organisation={current} />
            </div>
        </nav>
    );
};

Navigation.propTypes = {
    userRoleLevel: PropTypes.number
};

export default Navigation;