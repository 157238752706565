import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import ButtonBack from "../buttons/ButtonBack";
import StepBullet from "./StepBullet";

import "./style.scss";

const Steps = ({
    title,
    steps,
    stepsCompleted,
    currentStep,
    onStep,
    onBack,
    showLogo,
}) => {

    const [t] = useTranslation();

    return (
        <div className="container-steps">
            <aside className="sidebar-steps">
                <div className="container-header">
                    <ButtonBack onClick={onBack} label={t("button.back")} />
                    {showLogo &&
                        <img
                            src="/images/header.png"
                            alt="OKCOMPLY"
                            className="sidebar-logo"
                        />
                    }
                    <h1 className="light">{title}</h1>
                </div>
                <div className="steps">
                    {steps.map((step, index) => {
                        return (
                            <StepBullet
                                label={step.label}
                                index={index}
                                key={index}
                                completed={stepsCompleted[index]}
                                active={index === currentStep}
                                onClick={onStep}
                                testId={step.testId} />
                        );
                    })}
                </div>
                {steps[currentStep]?.instructions &&
                    <div className="container-instructions">
                        <span>{steps[currentStep]?.instructions}</span>
                    </div>
                }
            </aside>
            <div className="current-step">
                {steps[currentStep]?.component}
            </div>
        </div>
    );
};

Steps.propTypes = {
    title: PropTypes.string.isRequired,
    currentStep: PropTypes.number.isRequired,
    onStep: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    steps: PropTypes.array.isRequired,
    stepsCompleted: PropTypes.array.isRequired,
    showLogo: PropTypes.bool,
};

export default Steps;
