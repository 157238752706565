import { v4 as uuidv4 } from "uuid";

import { BOOK_CHAPTER_UPDATED } from "redux/types";
import bookChaptersUpdated from "./bookChaptersUpdated";

export default function bookChapterAdd(osbn, chapter) {
    return (dispatch) => {
        
        const chapterId = uuidv4();
        
        dispatch({
            type: BOOK_CHAPTER_UPDATED,
            osbn,
            chapterId,
            chapter
        });
        
        dispatch(bookChaptersUpdated(osbn));

    };
}