import produce from "immer";

import {
    BOOKS_DRAFTS_LOADED,
    BOOKS_PUBLISHED_LOADED,
    BOOK_CHAPTERS_SORTED, 
    BOOK_CHAPTER_REMOVED, 
    BOOK_CHAPTER_SORTED, 
    BOOK_CHAPTER_UPDATED, 
    BOOK_FLOW_ITEM_REMOVED, 
    BOOK_FLOW_ITEM_UPDATED, 
    BOOK_LOADED
} from "../types";
import bookReducer from "./bookReducer";

const initialState = {
    books: {},
    published: {},
    loading: true
};

const booksReducer = produce((draft, action) => {
    switch (action.type) {
        case BOOKS_DRAFTS_LOADED: {
            draft.loading = false;
            draft.books = action.books;
            break;
        }
        case BOOKS_PUBLISHED_LOADED: {
            draft.loading = false;
            draft.published = action.books;
            break;
        }
        case BOOK_LOADED: {
            draft.loading = false;
            draft.books[action.id] = action.book;
            break;
        }
        case BOOK_FLOW_ITEM_UPDATED: 
        case BOOK_FLOW_ITEM_REMOVED: 
        case BOOK_CHAPTER_SORTED: 
        case BOOK_CHAPTERS_SORTED: 
        case BOOK_CHAPTER_REMOVED: 
        case BOOK_CHAPTER_UPDATED: {
            draft.books[action.osbn] = bookReducer(draft.books[action.osbn], action);
            break;
        }
        default:
            return;
    }
}, initialState);

export default booksReducer;
