import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import Loading from "shared/components/Loading";
import Chapters from "./sections/Chapters";
import DefaultContent from "./sections/DefaultContent";
import General from "./sections/General";
import Measures from "./sections/Measures";
import Publish from "./sections/Publish";
import Sidebar from "./Sidebar";

import "./style.scss";

const BookEdit = () => {

    const { osbn, currentSection } = useParams();
    const { books, loading } = useSelector(state => state.books);
    const book = books[osbn];

    const sections = {
        general: <General />,
        measures: <Measures />,
        chapters: <Chapters />,
        default: <DefaultContent />,
        publish: <Publish />
    };

    return (
        <div className="container-book-edit">
            {!loading ?
                <>
                    <Sidebar book={book} />
                    <div className="edit-content">
                        {sections[currentSection]}
                    </div>
                </> :
                <Loading />
            }
        </div>

    );
};



export default BookEdit;