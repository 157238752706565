import axios from "axios";
import isDevelopment from "./isDevelopment";
import isTest from "./isTest";

export async function userResetPassword(email) {
    const domain = isDevelopment() || isTest() ?
        process.env.REACT_APP_AUTH0_DOMAIN :
        "login.okcomply.com";

    const client_id = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const connection = process.env.REACT_APP_AUTH0_ALLOWED_CONNECTION;
    const callbackUrl = `${window.location.origin}/login`;

    let payload = { client_id, connection, email, callbackUrl };
    const params = new URLSearchParams(payload);
    const request = `https://${domain}/dbconnections/change_password/?${params}`;

    return await axios.get(request);
}