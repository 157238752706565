import React from "react";
import { useTranslation } from "react-i18next";

import MarkDownView from "shared/components/MarkdownView";

function AuthSidebar() {

    const { t } = useTranslation();

    let sidebarText = t("authentication.sidebar.content");

    sidebarText = sidebarText.replaceAll("[b]", "<b>").replaceAll("[/b]", "</b>");
    sidebarText = sidebarText.replaceAll("[a]", "<a>").replaceAll("[/a]", "</a>");
    sidebarText = sidebarText.replaceAll("[li]", "<li>").replaceAll("[/li]", "</li>");
    sidebarText = sidebarText.replaceAll("[br]", "<br>").replaceAll("[/br]", "</br>");

    return (
        <div className="sidebar-landing">
            <img
                src="/images/header.png"
                alt="OKCOMPLY"
                className="sidebar-logo" />
            <div className="spacer-double" />
            <p className="modal-card-title">
                {t("authentication.sidebar.title")}
            </p>
            <div className="spacer-double" />
            <p>
                <MarkDownView text={sidebarText} />
            </p>
        </div>
    );
};

export default AuthSidebar;
