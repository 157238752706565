import client from "graphql/client";
import i18next from "i18next";
import { loader } from "graphql.macro";
import { BOOK_LOADED } from "redux/types";
import { snackbarSuccess, snackbarError } from "shared/redux/actions/Snackbar";
import createBookFromData from "helpers/createBookFromData";

const editBookMutation = loader("graphql/mutations/books/editBook.graphql");

export function bookEdit(osbn, label, author, description, cover) {
    return async (dispatch, getState) => {
        const { current: organisation } = getState().organisation;

        try {
            const { data } = await client.mutate({
                mutation: editBookMutation,
                variables: {
                    organisationId: organisation.id,
                    osbn,
                    label,
                    author,
                    description,
                    cover
                },
            });
            
            const { editBook: bookData } = data;
            const book = createBookFromData(bookData);

            dispatch({
                type: BOOK_LOADED,
                id: osbn,
                book
            });
            dispatch(snackbarSuccess(i18next.t("books.edit.success")));

        } catch (error) {
            console.error(error);
            dispatch(snackbarError(i18next.t("books.edit.failed")));
        }
    };
}