import { BOOK_CHAPTER_REMOVED } from "redux/types";
import bookChaptersUpdated from "./bookChaptersUpdated";

export default function bookChapterRemove(osbn, chapterId) {
    return async (dispatch) => {

        await dispatch({
            type: BOOK_CHAPTER_REMOVED,
            osbn,
            chapterId
        });

        dispatch(bookChaptersUpdated(osbn));
    };
}