import React, { useEffect, Fragment } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import BookInvitesDashboard from "./containers/invites/InvitesOverview";
import BookInviteAdd from "./containers/invites/InviteAdd";
import BookInviteDetails from "./containers/invites/InviteDetails";
import BookOverview from "./containers/manage/BookOverview";

import isAuthenticated from "./shared/authentication/isAuthenticated";
import Navigation from "./components/Navigation";
import organisationLoadAll from "redux/actions/organisation/organisationLoadAll";
import { invitesLoad } from "redux/actions/invites/invitesLoad";
import { booksLoadDrafts } from "redux/actions/books/booksLoadDrafts";
import BookEdit from "containers/manage/BookEdit";
import { userLoad } from "redux/actions/user/userLoad";
import OkSnackbar from "shared/components/OkSnackbar";
import Authentication from "shared/authentication/Authentication";
import { booksLoadPublished } from "redux/actions/books/booksLoadPublished";

const AppRouter = ({ history }) => {

    useEffect(() => {
        const path = history.location.pathname;
        if (!isAuthenticated() && path !== "/login") {
            history.push("/login");
        }
    }, [history]);

    const dispatch = useDispatch();

    const loadAll = async () => {
        await dispatch(organisationLoadAll());
        await dispatch(invitesLoad());
        await dispatch(userLoad());
        await dispatch(booksLoadDrafts());
        dispatch(booksLoadPublished());

    };
    useEffect(() => {
        loadAll();
    }, []);


    const unAuthenticatedRoutes = () => {
        return (
            <Fragment>
                <Route
                    path="/login"
                    render={() =>
                        <Authentication defaultRoute="/books" />
                    }
                />
            </Fragment>
        );
    };

    const authenticatedRoutes = () => {
        return (
            <div className="App">
                <Navigation />
                <Route
                    exact
                    path="/books"
                    component={BookOverview}
                />
                <Route
                    exact
                    path="/books/:osbn"
                    component={BookEdit}
                />
                <Route
                    exact
                    path="/books/:osbn/:currentSection/:currentItem"
                    component={BookEdit}
                />
                <Switch>
                    <Route
                        path="/invites/add"
                        component={BookInviteAdd}
                    />
                    <Route
                        path="/invites/resend/*"
                        component={BookInviteAdd}
                    />
                    <Route
                        path="/invites"
                        component={BookInvitesDashboard}
                    />
                </Switch>
                <Route
                    path="/invites/invite/*"
                    component={BookInviteDetails}
                />
                <Route exact path="/">
                    <Redirect to="/invites" />
                </Route>
                <OkSnackbar fixed />
            </div>
        );
    };

    return (
        <Switch>
            <Fragment>
                {isAuthenticated() ?
                    authenticatedRoutes() :
                    unAuthenticatedRoutes()
                }
            </Fragment>
        </Switch>
    );
};


AppRouter.propTypes = {
    history: PropTypes.object.isRequired,
};


export default withRouter(AppRouter);