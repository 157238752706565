import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";

import "./style.scss";

export const ICONS = {
    HAMMER: "gavel",
    USER: "user",
    FILE: "file-alt",
    CROSS: "times",
    COPY: "copy",
    CUBE: "cube",
    USERS: "users",
    ARCHIVE: "archive",
    BUILDING: "building",
    TIMES: "times",
    CHECK: "check",
    PLUS: "plus",
    EYE: "eye",
    EDIT: "edit",
    PLAN: "calendar-plus",
    BACK: "chevron-left",
    FORWARD: "chevron-right",
    UP: "chevron-up",
    DOWN: "chevron-down",
    BOOK: "book",
    CHAPTERS: "rectangle-vertical-history",
    CALENDAR: "calendar",
    LIST: "list",
    TASKS: "tasks",
    BULLHORN: "bullhorn",
    SITEMAP: "sitemap",
    FOLDER: "folder",
    INFO_CIRCLE: "info-circle",
    TRASH: "trash-alt",
    ANGLE_UP: "angle-up",
    ANGLE_DOWN: "angle-down",
    ANGLE_LEFT: "angle-left",
    ANGLE_RIGHT: "angle-right",
    DOWNLOAD: "download",
    QUESTION: "question",
    FILTER: "filter",
    MAIL: "envelope",
    APPS: "th",
    COUNT: "hashtag",
    USER_CIRCLE: "user-circle",
    LOCATION: "map-marker-alt",
    WARNING: "exclamation-triangle",
    DOSSIER: "folder-open",
    REPORT: "bullhorn",
    ORGANISATION: "sitemap",
    ARROW_LEFT: "arrow-left",
    ARROW_RIGHT: "arrow-right",
    EXCLAMATION_TRIANGLE: "exclamation-triangle",
    MENU: "ellipsis-h",
    COMMENT: "comment-dots",
    CLIPBOARD: "clipboard-list",
    SEARCH: "search",
    CLOSE: "times-circle",
    CLOCK: "clock",
    ENVELOPE: "envelope",
    NOTIFICATION: "bell",
    CAMERA: "camera",
    REDO: "redo",
    THUMBS_DOWN: "thumbs-down",
    THUMBS_UP: "thumbs-up",
    USER_TIMES: "user-times",
    CHECK_DOUBLE: "check-double",
    PENCIL: "pen",
    FILE_PEN: "bolt",
    RULER: "ruler-horizontal"
};

function Icon({
    name,
    customName,
    color,
    size,
    spacedLeft,
    spacedRight,
    children,
    tooltip,
    onClick,
}) {
    const classes = classnames(
        "okcomply-icon",
        color ? color : "",
        onClick || tooltip ? "interactive" : "",
        spacedLeft ? "spaced-left" : "",
        spacedRight ? "spaced-right" : "",
        customName ? `${customName} ${size || "normal"} custom` : "",
    );



    return (
        <>
            {!customName ?
                <FontAwesomeIcon
                    onClick={onClick}
                    className={classes}
                    icon={name}
                    size={size}
                /> :
                /*fallback for old bulma based icons */
                <span
                    onClick={onClick}
                    className={classes}
                />
            }
            {children && children}
            {tooltip &&
                <span className="tooltip-desktop noselect">
                    {tooltip}
                </span>
            }
        </>
    );
}


Icon.defaultProps = {
    size: undefined,
};

Icon.propTypes = {
    children: PropTypes.any,
    color: PropTypes.string,
    name: PropTypes.string,
    customName: PropTypes.string,
    size: PropTypes.string,
    inline: PropTypes.bool,
    spacedRight: PropTypes.bool,
    spacedLeft: PropTypes.bool,
    onClick: PropTypes.func,
    tooltip: PropTypes.any,
};

export default Icon;
