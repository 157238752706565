import client from "graphql/client";
import i18next from "i18next";
import { loader } from "graphql.macro";
import { snackbarError } from "shared/redux/actions/Snackbar";
import axios from "axios";

const signUploadUrl = loader("shared/graphql/mutations/files/signUploadUrl.graphql");
const fileUploaded = loader("shared/graphql/mutations/files/fileUploaded.graphql"); 

export default function fileAdd(file) {

    return async (dispatch, getState) => {


        const { current: organisation } = getState().organisation;

        const fileType = file.name.split(".").pop();

        try {
            const { data } = await client.mutate({
                mutation: signUploadUrl,
                variables: {
                    organisationId: organisation.id,
                    originalFilename: file.name,
                    fileType
                },
            });


            const { signUploadUrl: result } = data;

            const {
                signedUrl,
                filename,
                cacheControl,
                serverSideEncryption
            } = result;


            const options = {
                headers: {
                    "x-amz-server-side-encryption": serverSideEncryption,
                    "Cache-Control": cacheControl,
                    "Content-Type": file.type
                }
            };

            
            await axios.put(signedUrl, file, options);

            await client.mutate({
                mutation: fileUploaded,
                variables: {
                    organisationId: organisation.id,
                    filename,
                    label: file.name
                },
            });


            return filename;

        } catch (error) {
            console.error(error);
            dispatch(snackbarError(i18next.t("file.add.failed")));
        }
    };
}