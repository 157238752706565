export default function createBookFromData(bookData) {
    const measures = JSON.parse(bookData.measures);
    const chapters = JSON.parse(bookData.chapters);
    const measureKeys = Object.keys(measures);
    const chapterKeys = Object.keys(chapters);

    measureKeys.forEach((key) => {
        measures[key].id = key;
    });
    chapterKeys.forEach((key) => {
        chapters[key].id = key;
    });

    return {
        ...bookData,
        measures,
        chapters
    };
}