import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Icon, { ICONS } from "shared/components/Icon";
import { localeDate } from "shared/helpers/date";
import getImageUrl from "shared/helpers/getImageUrl";


import "./style.scss";

const BookItem = ({ book }) => {

    const { t } = useTranslation();
    
    const { current: organisation } = useSelector(state => state.organisation);
    const [coverImage, setCoverImage] = useState(book?.cover);

    const loadImage = async () => {
        if (book?.cover) {
            if (!book.cover.includes("https")) {
                const imageUrl = await getImageUrl(book.cover, organisation.id);
                setCoverImage(imageUrl);
            }
        }
    };

    useEffect(() => {
        loadImage();
    }, []);

    const { label, author, publishDate } = book;
    // placeholder until book has an owner
    const { profile } = useSelector(state => state.user);

    const { pending, active } = useSelector(state => state.bookInvites);

    const activeKeys = Object.keys(active);
    const pendingKeys = Object.keys(pending);

    const activeCurrentBook = activeKeys.filter((key) => {
        return active[key].bookId === book.id;
    });

    const pendingCurrentBook = pendingKeys.filter((key) => {
        return pending[key].bookId === book.id;
    });

    return (
        <Link
            to={`/books/${book.osbn}/general/basic`}
            className="book-item"
        >
            <div className="container-main">
                <img src={coverImage || require("assets/img/books-placeholder.png")} />
                <div className="container-details">
                    <div className="book-name">
                        {label}
                    </div>
                    <div className="book-author">
                        {author || `${profile?.name} ${profile?.surname}`}
                    </div>
                    <div className="book-stats">
                        <div className="stats-col">
                            <Icon name={ICONS.USERS} spacedRight />
                            <span>{activeCurrentBook.length}</span>
                        </div>
                        <div className="stats-col">
                            <Icon name={ICONS.MAIL} spacedRight />
                            <span>{pendingCurrentBook.length}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-bottom">
                {publishDate ?
                    <>
                        <span>{t("publish.book.publishedOn")}</span>
                        <span className="publish-date">{localeDate(publishDate)}</span>
                    </> :
                    <span>{t("publish.book.unPublished")}</span>
                }
            </div>
        </Link>
    );
};

BookItem.propTypes = {
    book: PropTypes.object.isRequired,
    progress: PropTypes.object
};

export default BookItem;