import produce from "immer";
import {
    INVITES_LOADED,
    INVITES_PENDING_ADD,
    INVITES_MESSAGE,
    INVITES_SET_STATUS,
    INVITES_SET_BOOK,
    INVITES_FILTER,
    INVITES_LOADING
} from "../types";

const initialState = {
    book: "",
    active: {},
    pending: {},
    current: null,
    loading: true,
    filters: {},
    message: {
        type: "",
        text: "",
    }
};

const invitesReducer = produce((draft, action) => {
    switch (action.type) {
        case INVITES_LOADING: {
            draft.loading = true;
            break;
        }
        case INVITES_LOADED: {
            draft.active = action.active;
            draft.pending = action.pending;
            draft.loading = false;
            break;
        }
        case INVITES_PENDING_ADD: {
            const { bookInvite } = action;
            draft.pending[bookInvite.id] = bookInvite;
            break;
        }
        case INVITES_MESSAGE: {
            const { message } = action;
            draft.message = message;
            break;
        }
        case INVITES_SET_STATUS: {
            const { id, status } = action;
            if (draft.active[id]) {
                draft.active[id].status = status;
            } else if (draft.pending[id]) {
                draft.pending[id].status = status;
            }
            break;
        }
        case INVITES_SET_BOOK: {
            draft.book = action.book;
            break;
        }
        case INVITES_FILTER : {
            draft.filters = action.filters;
            break;
        }
        default:
            return;
    }
}, initialState);

export default invitesReducer;
