import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import InputSelect from "shared/components/inputs/InputSelect";
import InputText from "shared/components/inputs/InputText";
import Icon from "shared/components/Icon";
import { ICONS } from "shared/components/Icon";
import Button from "shared/components/buttons/Button";
import { useForm } from "react-hook-form";

export const COMPARISONS = [
    "EQ", "GT", "LT"
];

const NumericComparison = ({
    index,
    comparison,
    flowItems,
    onChange,
    onRemove
}) => {
    const [open, setOpen] = useState(!comparison?.operator);
    const { control, handleSubmit, errors } = useForm();
    const { t } = useTranslation();

    const comparisonItems = COMPARISONS.map((key) => {
        return {
            label: t(`book.numericValue.operator.${key}`),
            value: key
        };
    });

    const onSubmit = (formValues) => {
        const { result, operator, value } = formValues;

        setOpen(false);
        onChange(index, {
            result,
            operator,
            value
        });
    };

    return (
        <div className="select-option-answer">
            <div className="select-option-answer-header">
                <div className="comparison-container">
                    <span className="comparison-operator">
                        {comparison?.operator ?
                            t(`book.numericValue.operator.${comparison?.operator}:`) :
                            t("book.numericValue.operator.notSelected")
                        }
                    </span>
                    <span className="comparison-value">{comparison?.value || 0}</span>
                </div>
                <div className="toggle-open">
                    <div className="vertical-divider" />
                    <Icon name={ICONS.EDIT} onClick={() => setOpen(!open)} />
                </div>
            </div>
            {open &&
                <div className="select-option-answer-form">
                    <div className="cols">
                        <div className="col">

                            <InputSelect
                                dark
                                label={t("book.numericValue.operator.label")}
                                name="operator"
                                control={control}
                                rules={{ required: true }}
                                error={errors.operator}
                                items={comparisonItems}
                                defaultValue={comparison?.operator}
                            />
                        </div>
                        <div className="col">

                            <InputText
                                dark
                                label={t("book.numericValue.value")}
                                name="value"
                                control={control}
                                rules={{ required: true }}
                                error={errors.result}
                                defaultValue={comparison?.value}
                                type="number"
                                items={flowItems} />
                        </div>

                    </div>
                    <InputSelect
                        dark
                        label={t("book.numericValue.result")}
                        name="result"
                        control={control}
                        rules={{ required: true }}
                        error={errors.result}
                        items={flowItems}
                    />
                    <div className="container-buttons">
                        <Button
                            outlined
                            dark
                            spacedRight
                            label={t("button.delete.label")}
                            onClick={() => onRemove(index)}
                        />
                        <Button
                            dark
                            label={t("button.save.label")}
                            onClick={handleSubmit(onSubmit)}
                        />
                    </div>
                </div>
            }
        </div>
    );
};

NumericComparison.propTypes = {
    comparison: PropTypes.object.isRequired,
    flowItems: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired
};

export default NumericComparison;