import BookItem from "components/BookItem";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { bookAdd } from "redux/actions/books/bookAdd";
import Button from "shared/components/buttons/Button";
import { ICONS } from "shared/components/Icon";

import "./style.scss";

const BookOverview = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { books, loading } = useSelector(state => state.books);
    const bookKeys = Object.keys(books);


    const addNewBook = async () => {
        const book = await dispatch(bookAdd());
        history.push(`/books/${book.osbn}/general/basic`);
    };
    
    if (loading) return null;

    return (
        <div className="container-book-overview">
            <div className="cols">
                <div className="col">
                    <span className="is-size-3 has-text-black">
                        {t("publish.books.title")}
                    </span>
                </div>
                <div className="col">
                    <div className="container-buttons">
                        <Button
                            spacedLeft
                            iconLeft={ICONS.PLUS}
                            label={t("books.book.new")}
                            onClick={() => {
                                addNewBook();
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="divider" />

            <div className="container-books">
                {bookKeys.map((key) => {
                    return (
                        <BookItem
                            book={books[key]}
                            key={key}
                        />
                    );
                })}
            </div>
        </div>

    );
};

export default BookOverview;