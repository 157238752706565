import { BOOK_CHAPTERS_SORTED } from "redux/types";
import bookChaptersUpdated from "./bookChaptersUpdated";


export default function bookChapterSort(osbn, firstChapter, secondChapter) {
    return async (dispatch, getState) => {

        const { chapters }  = getState().books.books[osbn];
        const chapterKeys = Object.keys(chapters);
        const sortedChapters = {};

        for(let i=0; i< chapterKeys.length; i++) {
            const chapterId = chapterKeys[i];
            if(chapterId === secondChapter) {
                sortedChapters[secondChapter] = chapters[secondChapter];
                sortedChapters[firstChapter] = chapters[firstChapter];

            } else if(chapterId !== firstChapter) {
                sortedChapters[chapterId] = chapters[chapterId];
            }
        }

                
        dispatch({
            type: BOOK_CHAPTERS_SORTED,
            osbn,
            chapters: sortedChapters,
        });
        

        dispatch(bookChaptersUpdated(osbn));



    };
}