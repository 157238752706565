import client from "../../../graphql/client";
import i18next from "i18next";
import { loader } from "graphql.macro";
import { addPendingBookInvite } from "./index";
import { snackbarError, snackbarSuccess } from "shared/redux/actions/Snackbar";

const inviteToBookMutation = loader("graphql/mutations/subscriptions/inviteToBook.graphql");

export function inviteToBook(formData, onSuccess) {
    return async (dispatch, getState) => {
        try {
            const organisationId = getState().organisation.current.id;
            const organisation = {
                name: formData.organisationName
            };

            const bookInvite = {
                name: formData.name,
                surname: formData.surname,
                note: formData.note,
                bookId: formData.bookId,
                osbn: formData.osbn,
                organisation
            };

            const { data } = await client.mutate({
                mutation: inviteToBookMutation,
                variables: {
                    bookInvite,
                    email: formData.email,
                    organisationId
                }
            });

            const { inviteToBook } = data;
            const { id } = inviteToBook;

            dispatch(addPendingBookInvite(id, inviteToBook));
            dispatch(snackbarSuccess(i18next.t("subscription.add.success")));

            onSuccess && onSuccess();

        } catch (error) {
            dispatch(snackbarError(i18next.t("subscription.add.failed")));
        }
    };
}