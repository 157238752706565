import React, { useState } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "shared/components/buttons/Button";
import InputText from "shared/components/inputs/InputText";
import InputSelect from "shared/components/inputs/InputSelect";
import InputMarkdown from "shared/components/inputs/InputMarkdown";
import NumericComparison from "./NumericComparison";
import ButtonAdd from "shared/components/buttons/ButtonAdd";


const Numeric = ({ flowItem, flowItems, onChange, onCancel }) => {

    const { t } = useTranslation();
    const { control, handleSubmit, errors } = useForm();

    const [description, setDescription] = useState(flowItem.description);
    const [comparisons, setComparisons] = useState(flowItem.comparisons || []);

    const onSubmit = (form) => {
        const completeForm = { ...form, description };
        onChange(completeForm);
    };

    const onComparisonAdded = () => {
        const tmpComparisons = [...comparisons];
        tmpComparisons.push({ operator: null, value: 0, result: "END" });
        setComparisons(tmpComparisons);
    };


    const onComparisonUpdated = (index,comparison) => {
        const tmpComparisons = [...comparisons];
        tmpComparisons[index] = comparison;
        setComparisons(tmpComparisons);
    };
  

    const onComparisonRemoved = (index) => {
        const tmpComparisons = [...comparisons];
        tmpComparisons.splice(index, 1);
        setComparisons(tmpComparisons);
    };


    return (
        <div className="flow-item-form-content">
            <InputText
                label={t("book.numericValue.label")}
                name="label"
                defaultValue={flowItem.label}
                control={control}
                rules={{ required: true }}
                error={errors.label}
            />
            <InputMarkdown
                defaultValue={description}
                onChange={(markdown) => setDescription(markdown)}
            />
            <InputSelect
                label={t("book.numericValue.defaultResult")}
                defaultValue={flowItem.result}
                name="result"
                control={control}
                rules={{ required: true }}
                error={errors.result}
                items={flowItems}
            />
            <div className="spacer-double" />

            {comparisons?.length > 0 &&
                comparisons.map((comparison, index) => {
                    return (
                        <NumericComparison
                            comparison={comparison}
                            flowItems={flowItems}
                            index={index}
                            onChange={onComparisonUpdated}
                            onRemove={onComparisonRemoved}
                        />
                    );
                })
            }
            <ButtonAdd
                label={t("book.numericValue.addOption")}
                onClick={onComparisonAdded}
            />
            <div className="container-buttons">
                <Button
                    outlined
                    spacedRight
                    label={t("button.cancel.label")}
                    onClick={onCancel}
                />
                <Button
                    label={t("button.save.label")}
                    onClick={handleSubmit(onSubmit)}
                />
            </div>
        </div>
    );
};

export default Numeric;


Numeric.propTypes = {
    flowItem: PropTypes.object.isRequired,
    flowItems: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};
