import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useDispatch } from "react-redux";

import Icon from "shared/components/Icon";
import { ICONS } from "shared/components/Icon";
import DotMenu from "shared/components/DotMenu";
import FlowItem from "components/FlowItem";
import FlowItemForm from "components/FlowItemForm";
import Modal from "components/Modal";
import OkWarning from "shared/components/OkWarning";
import bookFlowItemRemove from "redux/actions/books/bookFlowItemRemove";
import ButtonAdd from "shared/components/buttons/ButtonAdd";
import bookChapterAutoSort from "redux/actions/books/bookChapterAutoSort";
import bookChapterSort from "redux/actions/books/bookChaptersSort";

import "./style.scss";

const ChapterItem = ({
    chapter,
    onEdit,
    onRemove,
    index,
    osbn,
    chapterId,
}) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [selectedFlowItem, setSelectedFlowItem] = useState(null);
    const [removeFlowItem, setRemoveFlowItem] = useState(null);
    const [isDragging, setIsDragging] = useState(false);

    const [isOpen, setOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const { items: flowItems } = chapter;
    const itemKeys = Object.keys(flowItems);

    // From here we alter the redux state due to the modal being in this component
    // TODO: check if it makes sense to move the modal further down the lane to the FlowItem itself
    const onFlowItemOpen = (id) => {
        setSelectedFlowItem(id);
        setShowModal(true);
    };

    const onFlowItemClose = () => {
        setShowModal(false);
        setSelectedFlowItem(null);
    };

    const onFlowItemRemove = (id) => {
        setRemoveFlowItem(id);
    };

    const onFlowItemAdded = async () => {
        setSelectedFlowItem(null);
        setShowModal(true);
    };

    const onSortFlowItems = async () => {
        dispatch(bookChapterAutoSort(osbn, chapterId));
    };

    const foldClasses = classNames(
        "fold",
        isOpen ? "open" : "closed"
    );


    const renderConfirmRemove = () => {
        return (
            <OkWarning
                title={t("book.flowItem.delete.confirm.title")}
                message={t("book.flowItem.delete.confirm.message")}
                onConfirm={() => {
                    dispatch(bookFlowItemRemove(osbn, chapterId, removeFlowItem));
                    setRemoveFlowItem(null);
                }}
                onCancel={() => setRemoveFlowItem(false)}
            />
        );
    };

    const onDrop = (e) => {
        console.log(e);
        var droppedChapterId = e.dataTransfer.getData("chapterId");
        dispatch(bookChapterSort(osbn, droppedChapterId, chapter.id));
    };

    const onDragOver = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const onDragStart = (e) => {
        e.dataTransfer.setData("chapterId", chapter.id);
        e.dataTransfer.setData("chapterLabel", chapter.label);
        setIsDragging(true);
    };

    const onDragEnd = () => {
        setIsDragging(false);
    };

    return (
        <div className="chapter-item">
            <div
                className="chapter-header"
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onDrop={onDrop}
                onDragOver={onDragOver}
                draggable="true"
            >
                <div className="header-content">
                    <div onClick={() => setOpen(!isOpen)} className={foldClasses}>
                        <Icon name={isOpen ? ICONS.UP : ICONS.DOWN} />
                    </div>
                    <span className="indicator">{index}</span>
                    <span className="label">{chapter.label}</span>
                </div>
                <div className="actions">
                    <DotMenu
                        menuItems={[
                            { action: () => onEdit(chapter.id), label: t("book.chapter.edit.label") },
                            { action: () => onRemove(chapter.id), label: t("book.chapter.remove.label") },
                            { action: () => onSortFlowItems(), label: t("book.chapter.autoSort.label") }
                        ]}
                    />
                </div>
            </div>
            {isOpen &&
                <div className="chapter-content">
                    {itemKeys.map((key, i) => {
                        return (
                            <FlowItem
                                item={flowItems[key]}
                                id={key}
                                key={key}
                                chapterIndex={index}
                                index={i}
                                onEdit={onFlowItemOpen}
                                onRemove={onFlowItemRemove}
                                chapter={chapter}
                            />
                        );
                    })}
                    <ButtonAdd
                        label={t("book.chapter.addItem")}
                        onClick={() => onFlowItemAdded()}
                    />
                </div>
            }
            {showModal &&
                <Modal
                    title={t("flowItem.modal.title")}
                    onClose={() => setShowModal(false)}
                    tabs={[{
                        tabName: "info",
                        component:
                            <FlowItemForm
                                id={selectedFlowItem}
                                osbn={osbn}
                                chapterId={chapterId}
                                flowItem={selectedFlowItem ? flowItems[selectedFlowItem] : null}
                                onCancel={onFlowItemClose}
                            />
                    }]}
                />
            }
            {removeFlowItem && renderConfirmRemove()}

        </div>
    );
};


ChapterItem.propTypes = {
    chapter: PropTypes.object.isRequired,
    onEdit: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    osbn: PropTypes.string.isRequired,
    chapterId: PropTypes.string.isRequired
};

export default ChapterItem;