import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { bookPublish } from "redux/actions/books/bookPublish";

import Button from "shared/components/buttons/Button";
import Icon, { ICONS } from "shared/components/Icon";
import InputDate from "shared/components/inputs/InputDate";
import InputText from "shared/components/inputs/InputText";
import OkWarning from "shared/components/OkWarning";

import "./style.scss";

const Publish = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { osbn } = useParams();

    const { control, handleSubmit, errors } = useForm();
    const { books } = useSelector(state => state.books);

    const [pendingPublish, setPendingPublish] = useState(null);

    const book = books[osbn];

    const updateBook = async (publishForm) => {
        setPendingPublish(publishForm);
    };


    const renderConfirmPublish = () => {
        const { publishDate, publishNotes } = pendingPublish;

        return (
            <OkWarning
                title={t("book.publish.confirm.title")}
                message={t("book.publish.confirm.message")}
                onConfirm={() => {
                    dispatch(bookPublish(osbn, publishDate, publishNotes));
                    setPendingPublish(null);
                }}
                onCancel={() => setPendingPublish(null)}
            />
        );
    };

    const chapterIds = Object.keys(book.chapters);
    const measureIds = Object.keys(book.measures);

    const flowItemIds = [];

    const notArchivedMeasureIds = measureIds.filter((key)=>{
        const measure = book.measures[key];
        return !measure.archived;
    });

    const bookString = JSON.stringify(book);
    chapterIds.forEach((chapterId) => {
        flowItemIds.push(...Object.keys(book.chapters[chapterId].items));
    });

    const hasDisconnectedFlowItems = flowItemIds.some((id) => {
        const exp = new RegExp(id, "g");
        return (bookString.match(exp) || []).length < 2;
    });

    const hasDisconnectedMeasures = notArchivedMeasureIds.some((id) => {
        const exp = new RegExp(id, "g");
        return (bookString.match(exp) || []).length < 3;
    });

    return (
        <div className="section-general">
            <h2>{t("book.edit.publish.title")}</h2>
            <div className="divider" />

            {hasDisconnectedFlowItems &&
                <span className="disconnected">
                    <Icon name={ICONS.WARNING} spacedRight />
                    {t("book.publish.disconnectedFlowItems")}
                </span>
            }
            {hasDisconnectedMeasures &&
                <span className="disconnected">
                    <Icon name={ICONS.WARNING} spacedRight />
                    {t("book.publish.disconnectedMeasures")}
                </span>
            }
            <div className="spacer" />

            <InputDate
                name="publishDate"
                label={t("book.publish.publishDate")}
                defaultValue={book.publishDate || new Date()}
                control={control}
                error={errors.publishDate}
                dataTestId="publishDate"
            />
            <InputText
                name="publishNotes"
                label={t("book.publish.publishNotes")}
                defaultValue={book.publishNotes}
                control={control}
                rules={{ required: true }}
                error={errors.publishNotes}
                multiline
                dataTestId="publishNotes"
            />
            <div className="divider" />
            <Button
                // disabled={disconnectedMeasures || disconnectedFlowItems}
                label={t("button.publish.label")}
                onClick={handleSubmit(updateBook)}
            />
            {pendingPublish && renderConfirmPublish()}
        </div>
    );
};

export default Publish;