import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

import ListHeader from "shared/components/ListHeader";
import Button from "shared/components/buttons/Button";
import { ICONS } from "shared/components/Icon";
import InviteItem from "components/InviteItem";
import { invitesLoad } from "redux/actions/invites/invitesLoad";
import organisationLoadAll from "redux/actions/organisation/organisationLoadAll";
import { booksLoadPublished } from "redux/actions/books/booksLoadPublished";
import FilterInvites from "components/FilterInvites";
import Loading from "shared/components/Loading";


import "./style.scss";


const InviteOverview = () => {

    const [t] = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const [activeColumn, setActiveColumn] = useState(1);
    const [filterActive, setFilterActive] = useState(false);
    const bookInvites = useSelector(state => state.bookInvites);
    const { active, pending, loading } = bookInvites;
    const activeKeys = Object.keys(active);
    const pendingKeys = Object.keys(pending);

    const loadAll = async () => {
        await dispatch(organisationLoadAll());
        await dispatch(invitesLoad());
        dispatch(booksLoadPublished());
    };
    useEffect(() => {
        loadAll();
    }, []);

    const renderPendingBookInvites = () => {
        return pendingKeys.map((key, index) => {
            const item = bookInvites.pending[key];
            return (
                <InviteItem
                    key={index}
                    pending
                    bookInvite={item} />
            );
        });
    };

    const renderActiveBookInvites = () => {
        return activeKeys.map((key) => {
            const item = bookInvites.active[key];
            return (
                <InviteItem
                    key={key}
                    active
                    bookInvite={item} />
            );
        });
    };

    const activeClassName = classnames("phase-selectable-column", !activeColumn && "selected");
    const pendingClassName = classnames("phase-selectable-column", activeColumn && "selected");

    return (
        <div className="book-invites-dashboard">
            <div className="cols">
                <div className="col">
                    <span className="is-size-3 has-text-black">{t("books.invites.title")}</span>
                </div>
                <div className="col">
                    <div className="container-buttons">
                        <Button
                            outlined
                            iconRight={ICONS.FILTER}
                            onClick={() => setFilterActive(!filterActive)}
                            label={t("books.invites.filter")}
                        />
                        <Button
                            spacedLeft
                            iconLeft={ICONS.PLUS}
                            label={t("books.invites.new")}
                            onClick={() => {
                                history.push("/invites/add");
                            }}
                        />
                    </div>
                </div>
            </div>
            {filterActive &&
                <>
                    <div className="spacer" />
                    <FilterInvites onClose={() => setFilterActive(false)} />
                </>
            }
            <div className="divider" />
            {loading ?
                <Loading /> :
                <>
                    <div className=" phase-header-container">
                        <div className="phase-header-content">
                            <ListHeader
                                label={t("books.invites.active")}
                                phase="accepted"
                                icon={ICONS.USERS}
                                numberOfItems={activeKeys.length}
                                onClick={setActiveColumn}
                                index={0}
                                isSelected={activeColumn === 0}
                            />
                            <ListHeader
                                label={t("books.invites.pending")}
                                phase="pending"
                                icon={ICONS.MAIL}
                                numberOfItems={pendingKeys.length}
                                onClick={setActiveColumn}
                                index={1}
                                isSelected={activeColumn === 1}
                            />
                        </div>
                    </div>
                    <div className="cols">
                        <div className={activeClassName}>
                            {renderActiveBookInvites()}
                        </div>
                        <div className={pendingClassName}>
                            {renderPendingBookInvites()}
                        </div>
                    </div>
                </>
            }

        </div>
    );
};

export default InviteOverview;