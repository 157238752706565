
import { BOOK_CHAPTER_SORTED } from "redux/types";
import bookChaptersUpdated from "./bookChaptersUpdated";

export default function bookChapterAutoSort(osbn, chapterId) {
    return (dispatch, getState) => {

        const { books } = getState().books;
        const { items, startItem } = books[osbn].chapters[chapterId];
        const sortedItems = {};
        const sortedKeys = [];

        const sortNextItem = (itemId) => {
            if (sortedItems[itemId]) {
                return;
            }
            const nextItem = items[itemId];
            sortedKeys.push(itemId);

            sortedItems[itemId] = nextItem;

            if (nextItem.result && nextItem.result !== "END") {
                sortNextItem(nextItem.result);
            } else if (nextItem.answers) {
                nextItem.answers.forEach((answer) => {
                    if (answer.result) {
                        sortNextItem(answer.result);
                    }
                });
            }
        };

        sortNextItem(startItem);

        const unsortedKeys = Object.keys(items);
        unsortedKeys.forEach((key) => {
            if (!sortedKeys.includes(key)) {
                sortedItems[key] = items[key];
            }
        });

        dispatch({
            type: BOOK_CHAPTER_SORTED,
            osbn,
            chapterId,
            sortedItems
        });

        dispatch(bookChaptersUpdated(osbn));
    };
}
