import { loader } from "graphql.macro";
import client from "../../graphql/client";


const fetchFile = loader("shared/graphql/queries/general/fetchFile.graphql");

export default async function getImageUrl(filename, organisationId) {

    const { data } = await client.query({
        query: fetchFile,
        variables: {
            organisationId,
            filename,
        },
        fetchPolicy: "network-only"
    });

    const { file } = data;
    const { eTag, signedUrl } = file;

    const headers = new Headers();
    headers.append("if-match", eTag);
    headers.append("Host", "dev-test-demo-okapi-files.s3.eu-central-1.amazonaws.com");
    headers.append("Access-Control-Request-Method", "GET");

    const request = new Request(signedUrl, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers
    });

    const response = await fetch(request);

    const blob = await response.blob();
    const fileURL = window.URL.createObjectURL(blob);
    return fileURL;

}