import Cookies from "universal-cookie";

export function clearSiteData() {
    // Clear the auth part of local storage
    localStorage.clear();
    const cookies = new Cookies();
    cookies.remove("access_token");
    cookies.remove("id_token");
    cookies.remove("expires_at");

    const otherCookies = document.cookie.split("; ");
    for (var c = 0; c < otherCookies.length; c++) {
        var d = window.location.hostname.split(".");
        while (d.length > 0) {
            var cookieBase = 
              encodeURIComponent(otherCookies[c].split(";")[0].split("=")[0]) + 
              "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" + d.join(".") + 
              " ;path=";
            var p = window.location.pathname.split("/");
            document.cookie = cookieBase + "/";
            while (p.length > 0) {
                document.cookie = cookieBase + p.join("/");
                p.pop();
            }
            d.shift();
        }
    }
}
