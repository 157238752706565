import { BOOK_FLOW_ITEM_REMOVED } from "redux/types";
import bookChaptersUpdated from "./bookChaptersUpdated";

export default function bookFlowItemRemove(osbn, chapterId, flowItemId) {
    return async (dispatch) => {
        
        await dispatch({
            type: BOOK_FLOW_ITEM_REMOVED,
            osbn,
            chapterId,
            flowItemId
        });

        dispatch(bookChaptersUpdated(osbn));

    };
}
