import React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "shared/components/buttons/Button";
import Icon, { ICONS } from "shared/components/Icon";
import InputMarkdown from "shared/components/inputs/InputMarkdown";
import InputText from "shared/components/inputs/InputText";

import "./style.scss";

const MeasureTaskItem = ({ onChange, onDelete, task, index }) => {

    const { t } = useTranslation();
    const [label, setLabel] = useState(task?.label);
    const [description, setDescription] = useState(task?.description);
    const [open, setOpen] = useState(!task?.label);

    return (
        <div className="task-item">
            <div className="task-header">
                <span>{label}</span>
                <div className="toggle-open">
                    <div className="vertical-divider" />
                    <Icon name={ICONS.EDIT} onClick={() => setOpen(!open)} />
                </div>
            </div>
            {open &&
                <div className="task-form">
                    <InputText
                        label={t("measure.task.label")}
                        defaultValue={label}
                        onChange={({ target }) => setLabel(target.value)}
                        dark
                    />
                    <InputMarkdown
                        label={t("measure.task.description")}
                        defaultValue={description}
                        onChange={(markdown) => setDescription(markdown)}
                    />
                    <div className="spacer" />
                    <div className="container-buttons">
                        <Button
                            outlined
                            dark
                            spacedRight
                            label={t("button.delete.label")}
                            onClick={() => onDelete(index)}
                        />
                        <Button
                            disabled={label.length === 0}
                            dark
                            label={t("button.save.label")}
                            onClick={() => {
                                setOpen(false);
                                onChange(index, {
                                    label,
                                    description
                                });
                            }}
                        />
                    </div>
                </div>
            }

        </div>
    );
};


export default MeasureTaskItem;

MeasureTaskItem.propTypes = {
    task: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired
};