import { combineReducers } from "redux";
import userReducer from "../reducers/userReducer";
import bookInvitesReducer from "../reducers/invitesReducer";
import organisationReducer from "../reducers/organisationReducer";
import booksReducer from "redux/reducers/booksReducer";
import snackbarReducer from "shared/redux/reducers/SnackbarReducer";

export default combineReducers({
    user: userReducer,
    snackbar: snackbarReducer,
    bookInvites: bookInvitesReducer,
    organisation: organisationReducer,
    books: booksReducer
});