import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import InputText from "../../../shared/components/inputs/InputText";
import Steps from "../../../shared/components/Steps";
import { resendInviteToBook } from "../../../redux/actions/invites/resendInviteToBook";
import { EMAIL_REGEX } from "../../../constants";
import getIdfromPath from "../../../helpers/getIdFromPath";
import { inviteToBook } from "../../../redux/actions/invites/inviteToBook";
import InputSelect from "../../../shared/components/inputs/InputSelect";
import { booksLoadPublished } from "redux/actions/books/booksLoadPublished";


import "./style.scss";

const InviteAdd = () => {

    const [t] = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const { register, handleSubmit, errors, control } = useForm();
    const [currentStep, setStep] = useState(0); 
    const { pending, active } = useSelector(state => state.bookInvites);
    const { published: books } = useSelector(state => state.books);

    const id = getIdfromPath(1);
    const bookInvite = (active[id] || pending[id]) || {};
    
    useEffect(() => {
        dispatch(booksLoadPublished());
    }, [dispatch]);

    const onSubmit = (formValues) => {

        const onSuccess = () => {
            history.push("/invites");
        };

        if (bookInvite && bookInvite.id) {
            const { sequenceNumber } = bookInvite;
            const newSequenceNumber = sequenceNumber + 1;
            dispatch(resendInviteToBook(id, newSequenceNumber, formValues, onSuccess));
        } else {
            dispatch(inviteToBook(formValues, onSuccess));
        }
    };

    const bookKeys = Object.keys(books);

    const items = bookKeys.map((key)=> {
        const book = books[key];
        return { label: book.label, value: book.osbn };

    });

    const bookInviteForm = () => {
        return (
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="form-book-invite"
                autoComplete="off">
                <h4>{t("books.invite.compose")}</h4>
                <div className="spacer" />
                <InputText
                    label={t("books.invite.organisationName")}
                    name="organisationName"
                    defaultValue={bookInvite?.organisation?.name}
                    error={errors.organisationName}
                    inputRef={register({ required: true })}
                />
                <div className="cols">
                    <div className="col">
                        <InputText
                            label={t("books.invite.name")}
                            name="name"
                            defaultValue={bookInvite?.name}
                            error={errors.name}
                            inputRef={register({ required: true })}
                        />
                    </div>
                    <div className="col">
                        <InputText
                            label={t("books.invite.surname")}
                            name="surname"
                            defaultValue={bookInvite?.surname}
                            error={errors.surname}
                            inputRef={register({ required: true })}
                        />
                    </div>
                </div>
                <InputText
                    label={t("books.invite.email")}
                    name="email"
                    defaultValue={bookInvite?.sendTo}
                    error={errors.email}
                    inputRef={register({
                        required: true,
                        pattern: {
                            value: EMAIL_REGEX,
                            message: t("formValidation.email")
                        }
                    })}
                />
                <InputSelect
                    items={items}
                    control={control}
                    defaultValue={bookInvite?.osbn}
                    label={t("books.invites.book")}
                    name="osbn"
                    error={errors.osbn}
                    rules={{ required: true }}
                />
                <InputText
                    label={t("books.invite.note")}
                    name="note"
                    defaultValue={bookInvite?.note}
                    error={errors.note}
                    multiline
                    helpText={t("books.invite.note.help")}
                    inputRef={register({ required: false })}
                />
                <div className="container-buttons">
                    <input
                        type="submit"
                        className="button is-success"
                        value={t("button.save.label")}
                    />
                </div>
            </form>
        );
    };

    const steps = [{
        label: t("books.invite.compose"),
        component: bookInviteForm()
    }];

    return (
        <div className="container-book-invite-add">
            <Steps
                title={t("books.invite.title")}
                steps={steps}
                onStep={setStep}
                stepsCompleted={[]}
                currentStep={currentStep}
                onBack={() => {
                    history.goBack();
                }}
            />
        </div>
    );
};

export default InviteAdd;
