// User
export const USER_LOADED = "USER_LOADED";

// Book invites
export const INVITES_LOADED = "INVITES_LOADED";
export const INVITES_PENDING_ADD = "INVITES_PENDING_ADD";
export const INVITES_MESSAGE = "INVITES_MESSAGE";
export const INVITES_SET_STATUS = "SUBSCRIPTIONS_SET_STATUS";
export const INVITES_SET_BOOK = "SUBSCRIPTIONS_SET_BOOK";
export const INVITES_FILTER = "INVITES_FILTER";
export const INVITES_LOADING = "INVITES_LOADING";

// Snackbar
export const SNACKBAR_SUCCESS ="SNACKBAR_SUCCESS";
export const SNACKBAR_ERROR = "SNACKBAR_ERROR";
export const SNACKBAR_HIDE = "SNACKBAR_HIDE";

// Organisation
export const ORGANISATION_LOADED = "ORGANISATION_LOADED";
export const ORGANISATIONS_LOADED = "ORGANISATIONS_LOADED";

export const BOOKS_PUBLISHED_LOADED = "BOOKS_PUBLISHED_LOADED";
export const BOOKS_DRAFTS_LOADED = "BOOKS_DRAFTS_LOADED";

export const BOOK_LOADED = "BOOK_LOADED";
export const BOOK_FLOW_ITEM_UPDATED = "BOOK_FLOW_ITEM_UPDATED";
export const BOOK_CHAPTER_UPDATED = "BOOK_CHAPTER_UPDATED";
export const BOOK_FLOW_ITEM_REMOVED = "BOOK_FLOW_ITEM_REMOVED";
export const BOOK_CHAPTER_SORTED = "BOOK_CHAPTER_SORTED";
export const BOOK_CHAPTERS_SORTED ="BOOK_CHAPTERS_SORTED";
export const BOOK_CHAPTER_REMOVED ="BOOK_CHAPTER_REMOVED";