import client from "graphql/client";
import i18next from "i18next";
import { loader } from "graphql.macro";
import { snackbarSuccess, snackbarError } from "shared/redux/actions/Snackbar";

const updateBookChapters = loader("graphql/mutations/books/updateBookChapters.graphql");

export default function bookChaptersUpdated(osbn) {
    return async (dispatch, getState) => {

        const { current: organisation } = getState().organisation;
        const { chapters } = getState().books.books[osbn];

        try {
            await client.mutate({
                mutation: updateBookChapters,
                variables: {
                    organisationId: organisation.id,
                    osbn,
                    chapters: JSON.stringify(chapters)
                },
            });

            dispatch(snackbarSuccess(i18next.t("books.chapters.update.success")));

        } catch (error) {
            console.error(error);
            dispatch(snackbarError(i18next.t("books.chapters.update.failed")));
        }
    };
}