import produce from "immer";

import { USER_LOADED } from "../types";

const initialState = {
    profile: {},
    loading: false,
};

const userReducer = produce((draft, action) => {
    switch (action.type) {
        case USER_LOADED:
            draft.profile = action.userProfile;
            return;
        default:
            return;
    }
}, initialState);


export default userReducer;