import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import InputText from "shared/components/inputs/InputText";
import Button from "shared/components/buttons/Button";
import Icon from "shared/components/Icon";
import { ICONS } from "shared/components/Icon";
import { useDispatch, useSelector } from "react-redux";

import bookChapterUpdate from "redux/actions/books/bookChapterUpdate";
import bookChapterAdd from "redux/actions/books/bookChapterAdd";

import fileAdd from "redux/actions/files/fileAdd";
import { useParams } from "react-router";
import getImageUrl from "shared/helpers/getImageUrl";
import InputSelect from "shared/components/inputs/InputSelect";

const ChapterForm = ({ chapter, onClose }) => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { osbn } = useParams();

    const { control, handleSubmit, errors } = useForm();
    const { current: organisation } = useSelector(state => state.organisation);

    const [heroFile, setHeroFile] = useState(null);
    const [heroImage, setHeroImage] = useState(chapter?.hero);


    const { items } = chapter || { items: [] };
    const itemKeys = Object.keys(items);

    const flowItems = itemKeys.map((key) => {
        const item = items[key];
        return {
            label: item.label,
            value: key
        };
    });


    const loadImage = async () => {
        if (chapter?.hero) {
            if (!chapter.hero.includes("https")) {
                const imageUrl = await getImageUrl(chapter.hero, organisation.id);
                setHeroImage(imageUrl);
            }
        }
    };

    useEffect(() => {
        loadImage();
    }, []);

    const fileRef = useRef(null);

    const updateChapter = async (formData) => {
        let filename = null;
        if (heroFile) {
            filename = await dispatch(fileAdd(heroFile));
        }
        if (chapter?.id) {
            await dispatch(bookChapterUpdate(
                osbn,
                chapter.id,
                {
                    label: formData.label,
                    hero: filename,
                    startItem: formData.startItem
                }
            ));
        } else {
            await dispatch(bookChapterAdd(
                osbn,
                { label: formData.label, hero: filename }
            ));
        }

        onClose();
    };


    const selectFile = () => {
        fileRef.current.click();
    };

    const onFileSelected = ({ target }) => {
        const files = target.files;
        setHeroFile(files[0]);
        setHeroImage(URL.createObjectURL(files[0]));
    };

    return (
        <div className="modal-form">
            <div className="container-hero">
                <img
                    className="img-hero"
                    src={heroImage}
                />
                <span className="hero-info">
                    {t("chapter.hero.info")}
                </span>
                <div onClick={selectFile} className="image-change">
                    <Icon name={ICONS.CAMERA} />
                </div>
            </div>
            <div className="spacer-double" />
            <input
                className="hidden-file"
                type="file"
                onChange={onFileSelected}
                ref={fileRef}
            />
            <InputText
                name="label"
                label={t("chapter.form.label")}
                defaultValue={chapter?.label}
                control={control}
                rules={{ required: true }}
                error={errors.label}
                dataTestId="label"
            />
            <InputSelect
                label={t("book.chapter.startItem")}
                defaultValue={chapter?.startItem}
                name="startItem"
                control={control}
                rules={{ required: false }}
                error={errors.result}
                items={flowItems}
            />
            <div className="container-buttons">
                <Button
                    secondary
                    outlined
                    spacedRight
                    label={t("button.delete.label")}
                    onClick={onClose}
                />
                <Button
                    secondary
                    label={t("button.save.label")}
                    onClick={handleSubmit(updateChapter)}
                />
            </div>
        </div>
    );
};

ChapterForm.propTypes = {
    chapter: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
};

export default ChapterForm;