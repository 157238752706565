import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { invitesFilter } from "redux/actions/invites/invitesFilter";
import InputSelect from "shared/components/inputs/InputSelect";
import InputText from "shared/components/inputs/InputText";


import "./style.scss";

const FilterInvites = ({ onClose }) => {

    const { filters } = useSelector(state => state.bookInvites);
    const { books } = useSelector(state => state.books);

    const [timeoutRef, setTimeoutRef] = useState();
    const [currentFilters, setCurrentFilters] = useState(filters);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        clearTimeout(timeoutRef);
        const ref = setTimeout(() => {
            dispatch(invitesFilter(currentFilters));
        }, 500);
        setTimeoutRef(ref);
    }, [currentFilters]);

    const bookKeys = Object.keys(books);

    const bookListItems =bookKeys.map(key => {
        const book = books[key];
        return { label: book.label, value: book.osbn };
    });

    const onChange = async ({ target }) => {
        const { name, value } = target;
        await setCurrentFilters({ ...currentFilters, [name]: value });
    };

    return (
        <div className="filter-invites">
            <header className="filter-head">
                <h3 className="light">{t("invites.filter.title")}</h3>
                <button
                    className="delete is-large"
                    data-testid="closeInviteFilter"
                    aria-label="close"
                    onClick={onClose}
                />
            </header>
            <div className="spacer" />
            <div className="cols">
                <div className="col">
                    <InputSelect
                        items={bookListItems}
                        label={t("books.invites.book")}
                        name="osbn"
                        onChange={onChange}
                        dark
                    />
                </div>
                <div className="col">
                    <InputText
                        label={t("books.invites.organisationName")}
                        name="organisationName"
                        onChange={onChange}
                        dark
                    />
                </div>
            </div>
        </div>
    );
}
FilterInvites.propTypes = {
    onClose: PropTypes.func.isRequired,
};


export default FilterInvites;