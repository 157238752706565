import React, { useState } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "shared/components/buttons/Button";
import InputText from "shared/components/inputs/InputText";
import InputSelect from "shared/components/inputs/InputSelect";
import InputMarkdown from "shared/components/inputs/InputMarkdown";


const Date = ({ flowItem, flowItems, onChange, onCancel }) => {

    const { t } = useTranslation();
    const { control, handleSubmit, errors } = useForm();

    const [description, setDescription] = useState(flowItem.description);

    const onSubmit = (form) => {
        const completeForm = { ...form, description };
        onChange(completeForm);
    };

    return (
        <div className="flow-item-form-content">
            <InputText
                label={t("book.dateInput.label")}
                name="label"
                defaultValue={flowItem.label}
                control={control}
                rules={{ required: true }}
                error={errors.label}
            />
            <InputMarkdown
                defaultValue={description}
                onChange={(markdown) => setDescription(markdown)}
            />
            <InputSelect
                label={t("book.dateInput.result")}
                defaultValue={flowItem.result}
                name="result"
                control={control}
                rules={{ required: true }}
                error={errors.result}
                items={flowItems}
            />
            <div className="spacer-double" />
            <div className="container-buttons">
                <Button
                    outlined
                    spacedRight
                    label={t("button.cancel.label")}
                    onClick={onCancel}
                />
                <Button
                    label={t("button.save.label")}
                    onClick={handleSubmit(onSubmit)}
                />
            </div>
        </div>
    );
};

export default Date;


Date.propTypes = {
    flowItem: PropTypes.object.isRequired,
    flowItems: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};
