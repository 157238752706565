import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import InputSelect from "shared/components/inputs/InputSelect";
import InputText from "shared/components/inputs/InputText";
import Icon from "shared/components/Icon";
import { ICONS } from "shared/components/Icon";
import Button from "shared/components/buttons/Button";


const indicators = [
    { label: "Green", value: "GREEN" },
    { label: "Orange", value: "ORANGE" },
    { label: "Red", value: "RED" },
];

export const CHOICE_INDICATOR = [
    "A", "B", "C", "D", "E", "F", "G"
];

const SelectOptionAnswer = ({
    index,
    answer,
    flowItems,
    onChange,
    onRemove
}) => {

    const [label, setLabel] = useState(answer.label);
    const [explanation, setExplanation] = useState(answer.explanation?.label);
    const [open, setOpen] = useState(!answer?.label);

    const [auditIndicator, setAuditIndicator] = useState(answer.auditIndicator);
    const [result, setResult] = useState(answer.result);

    const { t } = useTranslation();

    return (
        <div className="select-option-answer">
            <div className="select-option-answer-header">
                <div className="choice-container">
                    <span className="choice-indicator">
                        {CHOICE_INDICATOR[index]}
                    </span>
                    <span>{label}</span>
                </div>
                <div className="toggle-open">
                    <div className="vertical-divider" />
                    <Icon name={ICONS.EDIT} onClick={() => setOpen(!open)} />
                </div>
            </div>
            {open &&
                <div className="select-option-answer-form">
                    <div className="question-row">
                        <InputText
                            dark
                            label={t("book.selectOption.answer")}
                            defaultValue={label}
                            onChange={({ target }) => setLabel(target.value)}
                        />
                        <InputSelect
                            dark
                            label={t("book.selectOption.indicator")}
                            defaultValue={auditIndicator}
                            onChange={({ target }) => setAuditIndicator(target.value)}
                            items={indicators}
                        />
                    </div>
                    <InputText
                        dark
                        label={t("book.selectOption.explanation")}
                        defaultValue={explanation}
                        onChange={({ target }) => setExplanation(target.value)}
                    />
                    <InputSelect
                        dark
                        label={t("book.selectOption.result")}
                        defaultValue={result}
                        onChange={({ target }) => setResult(target.value)}
                        items={flowItems}
                    />
                    <div className="container-buttons">
                        <Button
                            outlined
                            dark
                            spacedRight
                            label={t("button.delete.label")}
                            onClick={() => onRemove(index)}
                        />
                        <Button
                            disabled={label.length === 0}
                            dark
                            label={t("button.save.label")}
                            onClick={() => {
                                setOpen(false);
                                onChange(index, {
                                    label,
                                    auditIndicator,
                                    result,
                                    value: label,
                                    explanation:
                                        explanation ? { label: explanation } :
                                            undefined
                                });
                            }}
                        />
                    </div>
                </div>
            }
        </div>
    );
};

SelectOptionAnswer.propTypes = {
    answer: PropTypes.object.isRequired,
    flowItems: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired
};

export default SelectOptionAnswer;