import {
    INVITES_LOADED,
    INVITES_PENDING_ADD,
    INVITES_SET_STATUS,
    INVITES_SET_BOOK
} from "../../types";

export function setStatusBookInvite(id, status) {
    return {
        type: INVITES_SET_STATUS,
        id,
        status
    };
}

export function addPendingBookInvite(id, bookInvite) {
    return {
        type: INVITES_PENDING_ADD,
        bookInvite: { id, ...bookInvite }
    };
}

export function saveBookInvites(active, pending) {
    return {
        type: INVITES_LOADED,
        active,
        pending
    };
}

export function setBook(book) {
    return {
        type: INVITES_SET_BOOK,
        book
    };
}
