import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import Modal from "components/Modal";
import FieldSet from "components/FieldSet";
import Button from "shared/components/buttons/Button";
import getIdfromPath from "helpers/getIdFromPath";
import { invitesLoad } from "redux/actions/invites/invitesLoad";
import { revokeBookInvite } from "redux/actions/invites/revokeBookInvite";
import { STATUS_REVOKED } from "../../../constants";
import { booksLoadPublished } from "redux/actions/books/booksLoadPublished";

import "./style.scss";


const InviteDetails = () => {

    const [t] = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const defaultId = getIdfromPath();
    const [id, setId] = useState(defaultId);

    const bookInvites = useSelector(state => state.bookInvites);
    const { pending, active, published: books } = bookInvites;

    useEffect(() => {
        dispatch(booksLoadPublished());
        dispatch(invitesLoad());
    }, [dispatch]);

    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "visible";
        };
    }, []);

    const renderBook = (bookId) => {
        if (!bookId) return "";
        if (!Array.isArray(books) || books.length === 0) return "";
        const book = books.find(book => book.id === bookId);
        return book?.label ? book.label : "";
    };

    const revokeInvite = async () => {

        const onSuccess = () => {
            history.push("/invites");
        };

        if (window.confirm(t("books.invite.revokeConfirm"))) {
            const newSequenceNumber = sequenceNumber + 1;
            await dispatch(revokeBookInvite(id, newSequenceNumber));
            setId(id);
            onSuccess();
        }
    };

    const resendInvite = () => {
        history.push(`/invites/resend/${id}`);
    };
    
    const bookInvite = (active[id] || pending[id]) || {};

    const {
        name,
        surname,
        sendTo,
        organisation,
        sentAt,
        acceptedBy,
        note,
        sequenceNumber,
        status,
        bookId
    } = bookInvite;

    const tab = {
        tabName: "info",
        component: (
            <div className="tab-content ">
                <div className="cols">
                    <div className="col">
                        <FieldSet
                            label={t("books.invite.organisationName")}
                            value={organisation?.name} />
                        <FieldSet
                            label={t("books.invite.email")}
                            value={sendTo} />
                        <FieldSet
                            label={t("books.invite.contact")}
                            value={`${name} ${surname}`} />
                        <FieldSet
                            label={t("books.invite.note")}
                            value={note} />
                    </div>
                    <div className="col">
                        <FieldSet
                            label={t("books.invite.invitedOn")}
                            value={moment(sentAt).format("ll")} />
                        <FieldSet
                            label={t("books.invite.books")}
                            value={renderBook(bookId)} />
                    </div>
                </div>
                <div className="divider" />
                {!acceptedBy && status !== STATUS_REVOKED &&
                <div className="container-buttons">
                    <Button
                        label={t("button.revoke.label")}
                        outlined
                        spacedRight
                        onClick={revokeInvite} />
                    <Button
                        spacedLeft
                        label={t("common.resend")}
                        onClick={resendInvite} />
                </div>
                }
            </div>
        ),
    };
    return (
        <div className="book-invite-detail">
            <Modal
                title={bookInvite ?
                    `${bookInvite.name} ${bookInvite.surname} (${bookInvite.sendTo})` :
                    t("not.found")
                }
                basePath={`/invites/invite/${id}`}
                tabs={[tab]}
                onClose={() => {
                    history.push("/invites");
                }}
            />
        </div>
    );
};

export default InviteDetails;