import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "shared/components/buttons/Button";
import InputMarkdown from "shared/components/inputs/InputMarkdown";
import InputText from "shared/components/inputs/InputText";
import ButtonAdd from "shared/components/buttons/ButtonAdd";
import MultipleChoiceAnswer from "./MultipleChoiceAnswer";


const MultipleChoice = ({
    flowItem,
    flowItems,
    onChange,
    onCancel
}) => {

    const { t } = useTranslation();

    const [answers, setAnswers] = useState(flowItem.answers || []);
    const [label, setLabel] = useState(flowItem.label);
    const [description, setDescription] = useState(flowItem.description);

    const onSave = () => {
        const multipleChoiceItem = {
            ...flowItem,
            label,
            description,
            answers
        };
        onChange(multipleChoiceItem);
    };

    const onAnswerAdded = () => {
        const tmpAnswers = [...answers];
        tmpAnswers.push({ label: "", auditIndicator: null, result: "END" });
        setAnswers(tmpAnswers);
    };

    const onAnswerRemoved = (index) => {
        const tmpAnswers = [...answers];
        tmpAnswers.splice(index, 1);
        setAnswers(tmpAnswers);
    };

    const onAnswerUpdated = (index,answer) => {
        const tmpAnswers = [...answers];
        tmpAnswers[index] = answer;
        setAnswers(tmpAnswers);
    };


    const renderOptions = () => {
        return answers.map((answer, index) => {
            return (
                <MultipleChoiceAnswer
                    answer={answer}
                    flowItems={flowItems}
                    index={index}
                    onChange={onAnswerUpdated}
                    onRemove={onAnswerRemoved}
                />
            );
        });
    };

    return (
        <div className="flow-item-form-content">
            <InputText
                label={t("book.multipleChoice.label")}
                name="label"
                defaultValue={label}
                onChange={({ target }) => setLabel(target.value)}
            />
            <InputMarkdown
                defaultValue={flowItem.description}
                onChange={(markdown) => setDescription(markdown)}
            />
            <div className="divider" />
            <h5 className="subtitle">{t("book.flowItem.answers.title")}</h5>
            {renderOptions()}
            <ButtonAdd
                label={t("book.chapter.addOption")}
                onClick={onAnswerAdded}
            />
            <div className="container-buttons">
                <Button
                    outlined
                    spacedRight
                    label={t("button.cancel.label")}
                    onClick={onCancel}
                />
                <Button
                    label={t("button.save.label")}
                    onClick={() => onSave()}
                />
            </div>
        </div>
    );
};

export default MultipleChoice;


MultipleChoice.propTypes = {
    flowItem: PropTypes.object.isRequired,
    flowItems: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};
