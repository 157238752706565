import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "@material-ui/core/Select";
import { useTranslation } from "react-i18next";
import { MenuItem, Chip, OutlinedInput } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import classNames from "classnames";
import FormHelperText from "@material-ui/core/FormHelperText/FormHelperText";

import "./style.scss";
import { Controller } from "react-hook-form";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

export default function InputMultiSelect({
    onChange,
    items,
    error,
    defaultValue,
    name,
    inputRef,
    label,
    dark,
    testId,
    control,
    rules,
    inputId = "members"
}) {

    const [t] = useTranslation();
    const [selectedItems, setItems] = useState(defaultValue);
    const mappedLabels = {};
    items.forEach(({ value, label }) => {
        mappedLabels[value] = label;
    });
    // Check for error messages
    let errorMessage = "";
    if (error) {
        errorMessage = t("formValidation.required");
    }

    useEffect(() => {
        setItems(defaultValue);
    }, [defaultValue]);

    const handleChange = (event) => {
        setItems(event.target.value);
        onChange && onChange(event.target.value);
    };

    const classes = classNames(
        "input-multi-select",
        dark ? "dark" : ""
    );

    const renderField = () => {
        return (
            <Select
                id={inputId}
                name={name}
                variant="outlined"
                labelId="chip-label"
                multiple
                inputRef={inputRef}
                value={selectedItems}
                error={error}
                onChange={handleChange}
                inputProps={{ "data-testid": testId }}
                MenuProps={MenuProps}
                input={
                    <OutlinedInput
                        labelWidth={label.length * 8}
                        id={inputId}
                    />
                }
                renderValue={(selected) => (
                    <div className="container-chips">
                        {selected.map((value) =>
                            <Chip
                                key={value}
                                label={mappedLabels[value]}
                            />
                        )}
                    </div>
                )}
            >
                {items.map((item, index) => (
                    <MenuItem
                        key={item.value} value={item.value}
                        id={`${testId}menuItem${index}`}
                        data-testid={`${testId}menuItem${index}`}
                    >
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
        );
    };


    return (
        !items ? null :
            <>
                <FormControl
                    className={classes}
                    variant="outlined"
                    fullWidth
                    name={name}
                    error={error}>
                    <InputLabel className="multi-label">{label}</InputLabel>
                    {control ?
                        // Render controlled field
                        <Controller
                            as={renderField()}
                            rules={rules}
                            name={name}
                            control={control}
                            defaultValue={defaultValue}
                        /> :
                        // Render uncontrolled field
                        renderField()
                    }
                    {errorMessage &&
                        <FormHelperText error={error}>
                            {errorMessage}
                        </FormHelperText>
                    }
                </FormControl>
            </>
    );
}

InputMultiSelect.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    defaultValue: PropTypes.array,
    inputRef: PropTypes.func,
    control: PropTypes.func,
    rules: PropTypes.object,
    dark: PropTypes.bool,
    testId: PropTypes.string,
    inputId: PropTypes.string
};
