import { BOOK_CHAPTER_UPDATED } from "redux/types";
import bookChaptersUpdated from "./bookChaptersUpdated";

export default function bookChapterUpdate(osbn, chapterId, chapter) {
    return (dispatch) => {
        
        dispatch({
            type: BOOK_CHAPTER_UPDATED,
            osbn,
            chapterId,
            chapter
        });
        
        dispatch(bookChaptersUpdated(osbn));

    };
}