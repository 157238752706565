import client from "../../../graphql/client";
import i18next from "i18next";
import { loader } from "graphql.macro";
import { setStatusBookInvite } from "./index";
import { snackbarError, snackbarSuccess } from "shared/redux/actions/Snackbar";

const revokeInviteMutation = loader("graphql/mutations/subscriptions/revokeBookInvite.graphql");

export function revokeBookInvite(inviteId, sequenceNumber) {
    return async (dispatch, getState) => {

        try {
            const organisation = getState().organisation.current;

            const { data } = await client.mutate({
                mutation: revokeInviteMutation,
                variables: {
                    series: { id: inviteId, sequenceNumber },
                    organisationId: organisation.id
                }
            });

            const { revokeBookInvite } = data;
            const { id, status } = revokeBookInvite;

            dispatch(setStatusBookInvite(id, status));
            dispatch(snackbarSuccess(i18next.t("subscription.revoke.success")));

            return { id };

        } catch(error) {
            console.error(error);
            dispatch(snackbarError(i18next.t("subscription.revoke.failed")));
        }
    };
}