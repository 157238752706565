import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import DotMenu from "shared/components/DotMenu";
import Icon, { ICONS } from "shared/components/Icon";

import "./style.scss";

const MeasureItem = ({ measure, onEdit, onChange, onDelete, chaptersString, published }) => {

    const { label } = measure;
    const { t } = useTranslation();

    const exp = new RegExp(measure.id, "g");
    const isConnected = (chaptersString.match(exp) || []).length >= 1;
    const isArchived = !!measure.archived;

    return (
        <div className="measure-item">
            <Icon name={ICONS.DOSSIER} spacedRight />
            {!isConnected &&
                <span className="not-connected">
                    <Icon
                        name={ICONS.WARNING}
                        spacedRight
                    />
                </span>
            }
            <span className="measure-item-label">
                <span>{label}</span>
                {isArchived ? <span>{t("book.measure.isArchived")}</span> : ""}
            </span>
            <DotMenu
                menuItems={[
                    { action: () => onEdit(measure.id), label: t("book.measure.edit") },
                    published ? (
                        isArchived ?
                            { action: () => onChange({ ...measure, archived: undefined }), label: t("book.measure.unarchive") } :
                            { action: () => onChange({ ...measure, archived: new Date() }), label: t("book.measure.archive") }
                    ) :
                        { action: () => onDelete(measure.id), label: t("book.measure.delete") }

                ]}
            />
        </div>
    );
};


MeasureItem.propTypes = {
    measure: PropTypes.object.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    chaptersString: PropTypes.string.isRequired,
    published: PropTypes.bool
};

export default MeasureItem;