import React from "react";
import PropTypes from "prop-types";
import Icon from "../Icon";

import "./style.scss";

const ListHeader = ({
    label,
    phase,
    icon,
    numberOfItems,
    hideNumber,
    isSelected,
    inactive,
    index,
    onClick }) => {
        
    return (
        <div
            onClick={() => { onClick(index); }}
            className={`phase-selectable-header ${isSelected ? "selected" : ""}`}>
            <div className="card no-shadow">
                <header className={`card-header ${inactive ? "inactive" : ""} card-phase-${phase}`}>
                    <p className="card-header-title">
                        <Icon
                            spacedRight
                            name={icon}
                            color="white"
                        />
                        <span className={`list-header-title card-phase-${phase}`}>
                            {label}
                        </span>
                    </p>
                    {!hideNumber &&
                        <div className="number-card">
                            {numberOfItems}
                        </div>}
                    <p />
                </header>
            </div>
        </div>
    );
};


ListHeader.defaultProps = {
    numberOfItems: 0
};

ListHeader.propTypes = {
    label: PropTypes.string.isRequired,
    phase: PropTypes.string.isRequired,
    numberOfItems: PropTypes.number,
    icon: PropTypes.string.isRequired,
    hideNumber: PropTypes.bool,
    inactive: PropTypes.bool,
    isSelected: PropTypes.bool,
    index: PropTypes.number,
    onClick: PropTypes.func,
};

export default ListHeader;