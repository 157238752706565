import { INVITES_FILTER } from "redux/types"
import { invitesLoad } from "./invitesLoad";

export function invitesFilter(filters) {
    return async (dispatch, getState) => {
        const { filters: oldFilters } = getState().bookInvites;
        // Check if the filters have changed, otherwise ignore
        if (JSON.stringify(oldFilters) !== JSON.stringify(filters)) {
            await dispatch({ type: INVITES_FILTER, filters });
            dispatch(invitesLoad());
        }
    }
}