import React, { useState } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "shared/components/buttons/Button";
import InputMarkdown from "shared/components/inputs/InputMarkdown";
import InputText from "shared/components/inputs/InputText";
import InputSelect from "shared/components/inputs/InputSelect";


const Info = ({ flowItem, flowItems, onChange, onCancel }) => {

    const { control, handleSubmit, errors } = useForm();
    const [description, setDescription] = useState(flowItem.description);
    const [result, setResult] = useState(flowItem.result);

    const { t } = useTranslation();

    const onSubmit = (form) => {
        const completeForm = { ...form, description };
        onChange(completeForm);
    };

    return (
        <div className="flow-item-form-content">
            <InputText
                label={t("book.textInfo.label")}
                name="label"
                defaultValue={flowItem.label}
                control={control}
                rules={{ required: true }}
                error={errors.label}
            />
            <InputMarkdown
                defaultValue={flowItem.description}
                onChange={(markdown) => setDescription(markdown)}
            />
            <InputSelect
                label={t("book.textInfo.result")}
                onChange={({ target }) => setResult(target.value)}
                name="result"
                items={flowItems}
                rules={{ required: true }}
                error={errors.result}
                defaultValue={result}
                control={control}
            />
            <div className="spacer-double" />
            <div className="container-buttons">
                <Button
                    outlined
                    spacedRight
                    label={t("button.cancel.label")}
                    onClick={onCancel}
                />
                <Button
                    label={t("button.save.label")}
                    onClick={handleSubmit(onSubmit)}

                />
            </div>
        </div>
    );
};

export default Info;

Info.propTypes = {
    flowItem: PropTypes.object.isRequired,
    flowItems: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};
