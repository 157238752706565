import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { bookSetDefaultContent } from "redux/actions/books/bookSetDefaultContent";
import Button from "shared/components/buttons/Button";
import InputMultiSelect from "shared/components/inputs/InputMultiSelect";
import InputSelect from "shared/components/inputs/InputSelect";

import "./style.scss";

const DefaultContent = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { control, handleSubmit, errors } = useForm();

    const { osbn } = useParams();
    const { books } = useSelector(state => state.books);

    const book = books[osbn];
    const { chapters, defaultChapters, startItem } = book;

    const updateBook = async ({ startItem, defaultChapters }) => {
        dispatch(bookSetDefaultContent(osbn, startItem, defaultChapters));
    };

    const chapterKeys = Object.keys(chapters);
    const chapterItems = chapterKeys.map(key => {
        return {
            label: chapters[key].label,
            value: key
        };
    });

    return (
        <div className="section-general">
            <h2>{t("book.edit.defaultContent.title")}</h2>
            <div className="divider" />
            <InputSelect
                name="startItem"
                label={t("book.edit.startItem")}
                defaultValue={startItem}
                control={control}
                items={chapterItems}
                rules={{ required: true }}
                error={errors.label}
                dataTestId="startItem"
            />
            <InputMultiSelect
                name="defaultChapters"
                label={t("book.edit.defaultChapters")}
                defaultValue={defaultChapters || []}
                items={chapterItems}
                control={control}
                rules={{
                    required: true,
                    validate: (value) => {
                        return value.length > 0;
                    }
                }}
                error={errors.label}
                dataTestId="defaultChapters"
            />
            <div className="divider" />
            <Button
                label={t("button.save.label")}
                onClick={handleSubmit(updateBook)}
            />
        </div>
    );
};

export default DefaultContent;