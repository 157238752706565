import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Tab = ({ activeTab, tabName, basePath }) => {

    const [t] = useTranslation();
    const className = classnames(activeTab === tabName && "is-active");

    return (
        <li className={className}>
            <Link to={`${basePath}/${tabName}`}>
                {t(`${tabName}.label`)}
            </Link>
        </li>
    );

};

Tab.propTypes = {
    activeTab: PropTypes.string.isRequired,
    tabName: PropTypes.string.isRequired,
    basePath: PropTypes.string.isRequired
};

export default Tab;
