import { v4 as uuidv4 } from "uuid";

import { BOOK_FLOW_ITEM_UPDATED } from "redux/types";
import bookChaptersUpdated from "./bookChaptersUpdated";

export default function bookFlowItemAdd(osbn, chapterId, flowItem) {
    return async (dispatch) => {
        const uuid = uuidv4();
        
        await dispatch({
            type: BOOK_FLOW_ITEM_UPDATED,
            osbn,
            chapterId,
            flowItemId: uuid,
            flowItem
        });

        dispatch(bookChaptersUpdated(osbn));


    };
}
