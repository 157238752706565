import React, { useState } from "react";
import PropTypes from "prop-types";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-prebuilt-basic-styles";
import showdown from "showdown";

import "./style.scss";

const converter = new showdown.Converter();

const InputMarkdown = ({
    label,
    onChange,
    defaultValue,
    dataTestId,
}) => {

    const [markdownText, setMarkdownText] = useState(defaultValue || "");

    const submitButton = {
        TAGNAME: "button",
        TYPE: "submit",
        NAME: "save"
    };

    return (
        <div
            data-testid={dataTestId}
            className="input-markdown">
            <CKEditor
                id={"ckeditor5"}
                config={{
                    placeholder: label,
                    toolbar: [
                        "bold", "italic","strikethrough",
                        "bulletedList", "numberedList", "link"
                    ]
                }}
                data={converter.makeHtml(defaultValue)}
                editor={ClassicEditor}
                onChange={(event, editor) => {
                    const markdown = converter.makeMarkdown(editor.getData());
                    setMarkdownText(markdown);
                }}
                onBlur={(event, editor) => {
                    setTimeout(function() {
                        var focused = event.explicitOriginalTarget || document.activeElement;
                        if (!focused ||
                            focused === document.body ||
                            (focused.tagName?.toLowerCase() === submitButton.TAGNAME ||
                             focused.type?.toLowerCase() === submitButton.TYPE ||
                             focused.name?.toLowerCase() === submitButton.NAME)) {
                            onChange(markdownText);
                        }
                    }, 1);
                }}
                onFocus={(event, editor) => {
                }}
                onReady={editor => {
                }}
            />
        </div>
    );
};

InputMarkdown.propTypes = {
    label: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    dataTestId: PropTypes.string,
};

export default InputMarkdown;
