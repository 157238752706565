import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import Icon, { ICONS } from "../../shared/components/Icon";
import { STATUS_ACCEPTED, STATUS_REVOKED, STATUS_SENT, STATUS_RESENT } from "../../constants";
import { useTranslation } from "react-i18next";

import "./style.scss";

const InviteItem = ({ bookInvite, active }) => {

    const { t } = useTranslation();

    const { organisation, sentAt, id, name, surname, status } = bookInvite;
    const { books } = useSelector(state => state.books);

    const renderStatus = () => {
        switch (status) {
            case STATUS_RESENT:
                return `${t("invite.status.resent")} ${moment(sentAt).format("ll")}`;
            case STATUS_REVOKED:
                return `${t("invite.status.revoked")}`;
            case STATUS_ACCEPTED:
                return `${t("invite.status.accepted")}`;
            case STATUS_SENT:
                return `${t("invite.status.sent")} ${moment(sentAt).format("ll")}`;
        }
    };

    const renderBook = (bookInvite) => {
        if (!bookInvite.bookId && !bookInvite.osbn) return "";

        if (bookInvite.osbn && books[bookInvite.osbn]) {
            return books[bookInvite.osbn].label;
        } else {
            const { bookId } = bookInvite;
            for (let key of Object.keys(books)) {
                const book = books[key];
                if (bookId === book.id) {
                    return book.label;
                }
            }
        }

        return "";
    };

    return (
        <Link to={`/invites/invite/${id}/info`}>
            <div className="item-book-invite">
                <p className="header">
                    <span className="invite-title">{organisation.name}</span>
                    <span className="status">{renderStatus()}</span>
                </p>
                <div className="content">
                    <div className="cols">
                        <div className="col">
                            <Icon spacedRight name={ICONS.USER} />
                            <span>{`${name} ${surname}`}</span>
                        </div>
                        <div className="col">
                            <Icon spacedRight name={ICONS.BOOK} />
                            {renderBook(bookInvite)}
                        </div>
                    </div>

                </div>
            </div>
        </Link>
    );

};

InviteItem.propTypes = {
    bookInvite: PropTypes.object,
    active: PropTypes.bool,
};

export default InviteItem;