import React from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import classnames from "classnames";

import "./style.scss";

const SidebarGroup = ({
    name,
    content,
    label
}) => {

    const { osbn, currentSection, currentItem } = useParams();
    const selected = name === currentSection;
    const groupClasses = classnames(
        "group-title",
        selected ? "selected" : "",
        // chapterFinished ? "completed" : "",
    );

    return (
        <div className="sidebar-group">
            <Link
                className={groupClasses}
                to={`/books/${osbn}/${name}/edit`}
            >
                <span className="book-tree-bullet" />
                <h5>{label}</h5>
            </Link>
            {selected && content && // Render all activated questions in this chapter
                <div className="tree-items">
                    {content.map((item) => {

                        const { name: itemName, label: itemLabel } = item;

                        // Are we currently displaying this question?
                        const itemSelected = currentItem === itemName;


                        const classesQuestion = classnames(
                            "book-tree-item",
                            // completed ? "completed" : "",
                            itemSelected ? "selected" : "",
                            "navigatable"
                        );
                        return (
                            <Link
                                to={`/books/${osbn}/${name}/${itemName}`}
                                className={classesQuestion}
                                key={itemName}
                            >
                                <span className="book-tree-bullet" />
                                <span className="question">{itemLabel}</span>
                            </Link>
                        );
                    })}
                </div>
            }
        </div>
    );
};

SidebarGroup.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    content: PropTypes.array,
};

export default SidebarGroup;
