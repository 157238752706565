import React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import DotMenu from "shared/components/DotMenu";
import Icon, { ICONS } from "shared/components/Icon";
import { CHOICE_INDICATOR } from "components/FlowItemForm/types/MultipleChoiceAnswer";

import "./style.scss";

const FlowItem = ({
    item,
    id,
    index,
    chapterIndex,
    onEdit,
    onRemove,
    chapter,
}) => {

    const { t } = useTranslation();
    const [isDragging, setIsDragging] = useState(false);

    const indicatorClasses = classNames(
        "indicator",
        item.type,
    );

    const onDrop = (event) => {
        console.log(event);
    };

    const onDragOver = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const onDragStart = () => {
        setIsDragging(true);
    };

    const onDragEnd = () => {
        setIsDragging(false);
    };

    const chapterString = JSON.stringify(chapter);

    const exp = new RegExp(id, "g");
    const isConnected = (chapterString.match(exp) || []).length >= 2;

    const flowItemClasses = classNames(
        "flow-item",
        isDragging && "dragging"
    );

    const renderNextItem = () => {

        if (item.result &&  chapter.items[item.result]) {
            const subIndicatorClasses = classNames(
                "indicator",
                chapter.items[item.result].type,
            );

            const itemKeys = Object.keys(chapter.items);
            let itemIndex = 0;

            itemKeys.some((key) => {
                itemIndex++;
                return key === item.result;
            });

            return (
                <span className={subIndicatorClasses}>
                    {`${chapterIndex}.${itemIndex}`}
                </span>
            );

        } else {
            return null;
        }

    };

    return (
        <div className="container-flow-item">
            <div
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onDrop={onDrop}
                onDragOver={onDragOver}
                draggable="true"
                className={flowItemClasses}
            >
                <div className="flow-content">
                    <span className={indicatorClasses}>
                        {`${chapterIndex}.${index + 1}`}
                    </span>
                    {!isConnected &&
                        <span className="not-connected">
                            <Icon
                                name={ICONS.WARNING}
                                spacedRight
                            />
                        </span>
                    }
                    <span className="label">{item.label || t(`book.flowItem.${item.type}`)}</span>
                    {renderNextItem()}
                </div>
                <div className="actions">
                    <DotMenu
                        menuItems={[
                            { action: () => onEdit(id), label: t("book.flowItem.edit.button") },
                            { action: () => onRemove(id), label: t("book.flowItem.delete.button") }
                        ]}
                    />
                </div>
            </div>
            {item.answers?.length > 0 &&
                <div className="answer-results">
                    {item.answers.map((answer, index) => {
                        if (!answer?.result || !chapter.items[answer.result]) {
                            return null;
                        }
                        const subIndicatorClasses = classNames(
                            "indicator",
                            chapter.items[answer.result].type,
                        );

                        const itemKeys = Object.keys(chapter.items);
                        let itemIndex = 0;

                        itemKeys.some((key) => {
                            itemIndex++;
                            return key === answer.result;
                        });

                        return (
                            <div className="answer-result">
                                <div className="answer-content">
                                    <span className="choice-indicator">
                                        {CHOICE_INDICATOR[index]}
                                    </span>
                                    <span>{answer.label}</span>
                                </div>
                                <span className={subIndicatorClasses}>
                                    {`${chapterIndex}.${itemIndex}`}
                                </span>
                            </div>
                        );
                    })}
                </div>
            }
        </div>
    );
};

FlowItem.propTypes = {
    item: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    chapterIndex: PropTypes.number.isRequired,
    onEdit: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    chapter: PropTypes.string,
};

export default FlowItem;