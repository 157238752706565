import React from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "shared/components/buttons/Button";
import InputSelect from "shared/components/inputs/InputSelect";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import InputMultiSelect from "shared/components/inputs/InputMultiSelect";
import InputText from "shared/components/inputs/InputText";


const ActivateChapters = ({ flowItem, flowItems, onChange, onCancel }) => {

    const { control, handleSubmit, errors } = useForm();
    const { t } = useTranslation();

    const { osbn } = useParams();
    const { books } = useSelector(state => state.books);

    const book = books[osbn];
    const { chapters } = book;

    const chapterKeys = Object.keys(chapters);
    const chapterItems = chapterKeys.map(key => {
        return {
            label: chapters[key].label,
            value: key
        };
    });


    const onSubmit = (form) => {
        onChange(form);
    };

    return (
        <div className="flow-item-form-content">
            <InputText
                label={t("book.activateChapters.label")}
                name="label"
                defaultValue={flowItem.label}
                control={control}
                rules={{ required: true }}
                error={errors.label}
            />
            <InputMultiSelect
                label={t("book.activateChapters.select")}
                defaultValue={flowItem.chapters || []}
                name="chapters"
                control={control}
                rules={{
                    required: true,
                    validate: (value) => {
                        return value.length > 0;
                    }
                }}
                error={errors.chapters}
                items={chapterItems}
            />
            <InputSelect
                label={t("book.flowItem.result")}
                defaultValue={flowItem.result}
                name="result"
                control={control}
                rules={{ required: true }}
                error={errors.result}
                items={flowItems}
            />
            <div className="spacer-double" />
            <div className="container-buttons">
                <Button
                    outlined
                    spacedRight
                    label={t("button.cancel.label")}
                    onClick={onCancel}
                />
                <Button
                    label={t("button.save.label")}
                    onClick={handleSubmit(onSubmit)}
                />
            </div>
        </div>
    );
};

export default ActivateChapters;

ActivateChapters.propTypes = {
    flowItem: PropTypes.object.isRequired,
    flowItems: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};
