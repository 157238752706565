import client from "../../../graphql/client";
import i18next from "i18next";
import { loader } from "graphql.macro";
import { addPendingBookInvite } from "./index";
import { snackbarError, snackbarSuccess } from "shared/redux/actions/Snackbar";

const resendInviteToBookMutation = loader("graphql/mutations/subscriptions/resendInviteToBook.graphql");

export function resendInviteToBook(id, sequenceNumber, formData, onSuccess) {
    return async (dispatch, getState) => {
        try {

            const organisationId = getState().organisation.current.id;
            const organisation = {
                name: formData.organisationName
            };

            const bookInvite = {
                name: formData.name,
                surname: formData.surname,
                note: formData.note,
                bookId: formData.bookId,
                osbn: formData.osbn,
                organisation
            };

            const { data } = await client.mutate({
                mutation: resendInviteToBookMutation,
                variables: {
                    series: { id, sequenceNumber },
                    bookInvite, email: formData.email,
                    organisationId
                }
            });

            const { resendInviteToBook } = data;

            dispatch(addPendingBookInvite(id, resendInviteToBook));
            dispatch(snackbarSuccess(i18next.t("subscription.resend.success")));

            onSuccess && onSuccess();

        } catch (error) {
            dispatch(snackbarError(i18next.t("subscription.resend.failed")));
        }
    };
}