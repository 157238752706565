import client from "graphql/client";
import i18next from "i18next";
import { loader } from "graphql.macro";
import { BOOK_LOADED } from "redux/types";
import { snackbarSuccess, snackbarError } from "shared/redux/actions/Snackbar";
import createBookFromData from "helpers/createBookFromData";

const publishBookMutation = loader("graphql/mutations/books/publishBook.graphql");

export function bookPublish(osbn, publishDate, publishNotes) {
    return async (dispatch, getState) => {
        const { current: organisation } = getState().organisation;

        try {
            const { data } = await client.mutate({
                mutation: publishBookMutation,
                variables: { 
                    organisationId: organisation.id,
                    osbn,
                    publishDate,
                    publishNotes
                },
            });

            const { publishBook: bookData } = data;
            const book = createBookFromData(bookData);

            dispatch({
                type: BOOK_LOADED,
                id: bookData.osbn,
                book
            });

            dispatch(snackbarSuccess(i18next.t("books.publish.success")));

            return book;
        } catch (error) {
            console.error(error);
            dispatch(snackbarError(i18next.t("books.publish.failed")));
        }
    };
}