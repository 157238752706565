import { BOOK_FLOW_ITEM_UPDATED } from "redux/types";
import bookChaptersUpdated from "./bookChaptersUpdated";

export default function bookFlowItemUpdate(osbn, chapterId, flowItemId, flowItem) {
    return async (dispatch) => {
        
        await dispatch({
            type: BOOK_FLOW_ITEM_UPDATED,
            osbn,
            chapterId,
            flowItemId,
            flowItem
        });
        
        dispatch(bookChaptersUpdated(osbn));

    };
}
