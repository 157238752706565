import { HttpLink } from "apollo-link-http";
import Cookies from "universal-cookie";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";

const cookies = new Cookies();
const token = cookies.get("id_token");

const isDev = (!process.env.NODE_ENV || process.env.NODE_ENV === "development");

const environment = isDev ? "development" :
    window.location.href.includes("acceptance") ?
        "acceptance" :
        "production";

const ENVIRONMENTS = {
    "development": "http://localhost:1337/graphql",
    "acceptance": "https://acceptance.okcomply.com/graphql",
    "production": "https://my.okcomply.com/graphql"
};
const client = new ApolloClient({
    link: new HttpLink({
        // uri: `${window.location.host}/graphql`,
        // TODO: FIXME: make alias for publish.okcomply.com/graphql in order
        // to connect with OKAPI without 3 different env files
        uri: ENVIRONMENTS[environment],
        headers: {
            authorization: `Bearer ${token}`,
        },
    }),
    cache: new InMemoryCache(),
});


export default client;