
import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import classNames from "classnames";


import Group from "../../../components/SidebarGroup";
import ButtonBack from "shared/components/buttons/ButtonBack";
import Icon, { ICONS } from "shared/components/Icon";

const Sidebar = ({ book, showMobile, onToggleMenu }) => {

    const history = useHistory();
    const { t } = useTranslation();
    const { label } = book;

    const sidebarClasses = classNames(
        "book-sidebar",
        showMobile ? "show-mobile" : ""
    );

    return (
        <div className={sidebarClasses}>
            <div className="container-nav">
                <ButtonBack
                    dark
                    label={t("books.menu.books")}
                    onClick={() => history.push("/books")}
                />
                <div className="button-close">
                    <Icon
                        name={ICONS.CLOSE}
                        onClick={onToggleMenu}
                    />
                </div>
            </div>
            <div className="spacer" />
            <h3>{label}</h3>
            <div className="container-sections">
                <Group
                    label={t("book.edit.sidebar.general")}
                    name="general"
                    content={[
                        { label: t("book.edit.sidebar.basic"), name: "basic" },
                        { label: t("book.edit.sidebar.template"), name: "template" },
                    ]}
                />
                <Group
                    label={t("book.edit.sidebar.measures")}
                    name="measures"
                />
                <Group
                    label={t("book.edit.sidebar.chapters")}
                    name="chapters"
                />
                <Group
                    label={t("book.edit.sidebar.defaultContent")}
                    name="default"
                />
                <Group
                    label={t("book.edit.sidebar.publish")}
                    name="publish"
                />
            </div>
        </div>
    );
};


Sidebar.propTypes = {
    book: PropTypes.object,
    showMobile: PropTypes.bool,
    onToggleMenu: PropTypes.func.isRequired
};

export default Sidebar;
