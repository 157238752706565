import client from "../../../graphql/client";
import i18next from "i18next";
import { loader } from "graphql.macro";
import { saveBookInvites } from "./index";
import { INVITES_LOADING } from "redux/types";
import { snackbarError } from "shared/redux/actions/Snackbar";

const fetchBookInvitesQuery = loader("../../../graphql/queries/subscriptions/fetchBookInvites.graphql");

const sortInvites = (a, b) => new Date(b.sentAt) - new Date(a.sentAt);

export function invitesLoad() {
    return async (dispatch, getState) => {
        try {

            dispatch({ type: INVITES_LOADING });
            const organisation = getState().organisation.current;
            const { filters } = getState().bookInvites;

            const { data } = await client.query({
                query: fetchBookInvitesQuery,
                fetchPolicy: "network-only",
                variables: {
                    organisationId: organisation.id,
                    ...filters,
                },
            });

            const { bookInvites } = data;
            const pending = {};
            const active = {};

            bookInvites
                .sort(sortInvites)
                .forEach((bookInvite) => {
                    bookInvite.acceptedBy ?
                        active[bookInvite.id] = bookInvite :
                        pending[bookInvite.id] = bookInvite;
                });

            dispatch(saveBookInvites(active, pending));

        } catch (error) {
            console.error(error);
            dispatch(snackbarError(i18next.t("bookInvites.loading.failed")));
        }
    };
}