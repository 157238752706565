import { ORGANISATION_LOADED } from "../../types";

export default function organisationSet(index) {
    return async (dispatch, getState) => {

        const { organisations, current } = getState().organisation;

        if (!current || organisations[index].id !== current.id) {
            await dispatch({
                type: ORGANISATION_LOADED,
                organisation: organisations[index]
            });
        }
    };
}