import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { bookEdit } from "redux/actions/books/bookEdit";
import fileAdd from "redux/actions/files/fileAdd";
import Button from "shared/components/buttons/Button";
import Icon from "shared/components/Icon";
import { ICONS } from "shared/components/Icon";
import InputText from "shared/components/inputs/InputText";
import getImageUrl from "shared/helpers/getImageUrl";


import "./style.scss";

const General = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { osbn } = useParams();
    const fileRef = useRef(null);
    const { control, handleSubmit, errors } = useForm();

    const { books } = useSelector(state => state.books);
    const { profile } = useSelector(state => state.user);
    const { current: organisation } = useSelector(state => state.organisation);

    const book = books[osbn];

    const [coverFile, setCoverFile] = useState(null);
    const [coverImage, setCoverImage] = useState(book?.cover);

    const loadImage = async () => {
        if (book?.cover) {
            if (!book.cover.includes("https")) {
                const imageUrl = await getImageUrl(book.cover, organisation.id);
                setCoverImage(imageUrl);
            }
        }
    };

    useEffect(() => {
        loadImage();
    }, []);

    const onFileSelected = ({ target }) => {
        const files = target.files;
        setCoverFile(files[0]);
        setCoverImage(URL.createObjectURL(files[0]));
    };


    const updateBook = async ({ label, author, description }) => {
        let filename = null;
        if (coverFile) {
            filename = await dispatch(fileAdd(coverFile));
        }
        dispatch(bookEdit(osbn, label, author, description, filename));
    };

    const selectFile = () => {
        fileRef.current.click();
    };

    const renderCover = () => {
        return (
            <div className="cover">
                <input
                    className="hidden-file"
                    type="file"
                    onChange={onFileSelected}
                    ref={fileRef}
                />
                <img src={coverImage || require("assets/img/books-placeholder.png")} />
                <div className="image-change">
                    <Icon
                        name={ICONS.CAMERA}
                        onClick={selectFile}
                    />
                </div>
                <div className="ratio">
                    5:7
                </div>
            </div>
        );
    };


    return (
        <div className="section-general">
            <h2>{t("book.edit.basic.title")}</h2>
            <div className="divider" />
            <div className="cols-m">
                {renderCover()}
                <div className="col">
                    <InputText
                        name="label"
                        label={t("book.edit.label")}
                        defaultValue={book.label}
                        control={control}
                        rules={{ required: true }}
                        error={errors.label}
                        dataTestId="label"
                    />
                    <InputText
                        name="author"
                        label={t("book.edit.author")}
                        defaultValue={book.author || `${profile?.name} ${profile?.surname}`}
                        control={control}
                        rules={{ required: true }}
                        error={errors.author}
                        dataTestId="author"
                    />

                </div>
            </div>
            <InputText
                name="description"
                label={t("book.edit.description")}
                defaultValue={book.description}
                control={control}
                rules={{ required: true }}
                error={errors.description}
                multiline
                dataTestId="description"
            />
            <div className="divider" />
            <Button
                label={t("button.save.label")}
                onClick={handleSubmit(updateBook)}
            />
        </div>
    );
};

export default General;